@import "../../styles/global";

.NewsFeed {
  &__container {
    background-color: $color-background;
    padding: gap-size();
    box-shadow: 0px 2px 10px #00000026;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  img {
    width: 12rem;
    margin-right: 2rem;
  }

  &__content {
    p {
      margin-bottom: 0;
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }

  &__title--cfb {
    font-size: 1.3rem;
    font-weight: 500;
    color: #f36d21;
  }

  &__link--cfb {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    color: #2d2d2d;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1;
    margin-top: gap-size(0.2);

    &:hover {
      color: $color-text-dark;
    }
  }
}
