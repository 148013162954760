.customLoader {
  &__cards {
    display: flex;
    margin-top: 4rem;
    justify-content: center;
  }

  &__cardWrapper {
    margin: 0 -25px;
    padding: 5px;
    opacity: 0;
    justify-content: center;
    align-items: center;
    animation: waves 5s linear infinite;

    &:nth-child(2) {
      animation-delay: 0.2s;
    }
    &:nth-child(3) {
      animation-delay: 0.4s;
    }
    &:nth-child(4) {
      animation-delay: 0.6s;
    }
    &:nth-child(5) {
      animation-delay: 0.8s;
    }
  }

  &__text {
    padding: 3rem 1rem 0;
    text-align: center;
    line-height: 1.3;
    letter-spacing: 4px;
    font-size: 1.125em;
  }

  &__card {
    max-height: 150px;
    border-radius: 4px;
    position: relative;

    &--first {
      transform: rotate(-20deg);
      top: 10px;
    }
    &--second {
      transform: rotate(-10deg);
      top: -12px;
    }

    &--third {
      top: -19px;
    }

    &--fourth {
      transform: rotate(10deg);
      top: -12px;
    }
    &--fifth {
      transform: rotate(20deg);
      top: 10px;
      transform: rotate(20deg);
    }
  }

  @keyframes waves {
    0% {
      transform: translateY(0);
      opacity: 0;
    }
    4% {
      transform: translateY(-25px);
      opacity: 1;
    }
    8% {
      transform: translateY(0);
      opacity: 1;
    }
    70% {
      opacity: 0;
    }
  }
}
