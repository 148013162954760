@import "../../../styles/global";

.ebay-remove-shipping-service {
  padding: gap-size();

  &__actions {
    display: flex;
    justify-content: flex-end;
    line-height: 2;

    & > * {
      margin-left: gap-size();
    }
  }
}
