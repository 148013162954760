@import "../../../styles/global";

.MobileHeader {
  &__navbar {
    position: absolute;
    height: 85px;
    left: 0;
    top: 0;
    width: 100vw;
    background: url(../../../assets/img/watermarkMenu.png), #0a162e;
    background-repeat: no-repeat;
    background-position-x: 202;
    background-position: right;

    > * {
      margin-right: 0.5rem;
    }
  }

  &__topNav {
    position: absolute;
    top: 0;
    width: calc(100vw - 1rem);
    height: 85px;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    padding-right: 5rem;
    > * {
      margin-left: 1.3rem;
    }
  }

  &__navIcon {
    color: $color-text-light;
    &:hover {
      cursor: pointer;
      color: $color-grey-light;
    }
  }

  &__watermark {
  }

  &__brandLogo {
  }

  &__tillDetails {
  }

  &__sideBar {
  }

  &__top-corner-background {
    position: absolute;
    z-index: 10000;
    background: $color-background;
    width: 504px;
    height: 155px;
    box-shadow: 1px 1px 5px #92a9a8;
    transform: rotate(-18deg);
    left: -34px;
    top: -74px;
    pointer-events: none;
  }

  &__top-corner-background-light {
    border-top: 0px solid transparent;
    border-bottom: 135px solid transparent;
    border-left: 1019px solid $color-primary;
    position: absolute;
    left: 64px;
    display: block;
    top: -7px;
    z-index: 2;
  }

  &__pos-angle {
    position: fixed;
    z-index: 3;
    background: rgba(255, 255, 255, 0.7);
    width: 504px;
    height: 155px;
    transform: rotate(45deg);
    left: -271px;
    bottom: 0;
    z-index: 1;
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
  }

  &__left-background-blur {
    background: #f1f1f1 0% 0% no-repeat padding-box;
    opacity: 0.95;
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    height: 3000px;
    position: absolute;
    right: 267px;
    top: -8px;
    width: 1000px;
    z-index: -3;
    transform: rotate(9deg);
  }
  &__left-background-angle {
    background: #4fbdbb;
    height: 3000px;
    position: absolute;
    right: -94px;
    top: 151px;
    width: 1000px;
    z-index: -4;
    transform: rotate(-12deg);
  }
}
