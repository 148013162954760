// Fonts
// -------------------------------------
$font-size-small: 0.8rem;
$font-size-standard: 0.9rem;
$font-size-default: 1rem;
$font-size-mid: 1.2rem;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

$font-family-secondary: "Source Sans Pro", sans-serif;

// Colors
// -------------------------------------
$color-black: #212529;
$color-white: #ffffff;
$color-grey-light: #f5f5f5;
$color-grey-mid: #e5e8e9;
$color-grey-inter: #a3a4aa;
$color-grey-dark: #5c5c5c;
$color-grey: #cccccc;
$color-teal: #00bdbc;
$color-teal-dark: #05a0a0;
$color-blue-dark: #0a162e;
$color-red: #990000;
$color-red-dark: #600;

// Color mappings
$color-text-light: $color-white;
$color-text-dark: $color-black;
$color-text-link: $color-teal;
$color-text-link-alt: $color-grey-inter;
$color-background: $color-white;
$color-primary: $color-teal;
$color-primary-dark: $color-teal-dark;
$color-secondary: $color-blue-dark;
$color-error: $color-red;
$color-error-background: $color-grey-mid;
$color-danger: $color-red-dark;

// Borders
// -------------------------------------
$border-width-fine: 1px; /* stylelint-disable-line */
$border-width-thick: 3px; /* stylelint-disable-line */
$border-radius-small: 0.25rem;
$border-radius-standard: 0.5rem;
$border-radius-large: 1.75rem;

// Shadows
// -------------------------------------
$shadow-standard: 0 3px 6px rgba(0, 0, 0, 0.16);
$shadow-light: 0px 2px 6px #0000000a;

// Spacing
// -------------------------------------
$gap-size: 1rem;

// Transitions
// -------------------------------------
$transition-speed-slow: 500ms;
$transition-speed-default: 350ms;
$transition-speed-fast: 200ms;
$transition-easing-default: ease-out;
$transition-all: all $transition-speed-default $transition-easing-default;
$transition-background: background $transition-speed-default
  $transition-easing-default;

// Sizes
// -------------------------------------
$button-min-width: 8rem;
