@import "../../../styles/global";

.DesktopMenu {
  width: 123px;
  height: calc(100vh - 176px);
  position: relative;
  padding-left: 43px;
  text-align: center;
  top: 165px;

  &__link {
    text-align: center;
    color: $color-primary;
    font-size: 30px;
    height: 35px;
    display: block;
    position: relative;
    margin-bottom: 25px;
    transition: all 1s;
    width: 50px;

    &.active,
    &:hover {
      color: $color-secondary;
    }

    &--pos {
      position: fixed;
      bottom: -3px;
      z-index: 9;
      left: 38px;
    }
  }
}
