@import "../../../styles/global";

.checkbox-component {
  &__input {
    &[type="checkbox"]:not(:checked),
    &[type="checkbox"]:checked {
      position: absolute;
      left: 0;
      opacity: 0.01;
    }

    &[type="checkbox"]:not(:checked) + label,
    &[type="checkbox"]:checked + label {
      position: relative;
      padding-left: 2.3em;
      font-size: 1.05em;
      line-height: 1.7;
      cursor: pointer;
    }

    /* checkbox */
    &[type="checkbox"]:not(:checked) + label:before,
    &[type="checkbox"]:checked + label:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 1.4em;
      height: 1.4em;
      border: 1px solid $color-grey-light;
      background: $color-background;
      border-radius: 0.2em;
      box-shadow: $shadow-light;
      transition: all 0.275s;
    }

    /* checked mark */
    &[type="checkbox"]:not(:checked) + label:after,
    &[type="checkbox"]:checked + label:after {
      content: "✓";
      position: absolute;
      top: 0.5em;
      left: 0.2em;
      font-size: 1.375em;
      color: $color-primary-dark;
      line-height: 0;
      -webkit-transition: all 0.2s;
      transition: all 0.2s;
    }

    /* checked mark change */
    &[type="checkbox"]:not(:checked) + label:after {
      opacity: 0;
    }

    &[type="checkbox"]:checked + label:after {
      opacity: 1;
    }

    /* Disabled checkbox */
    &[type="checkbox"]:disabled:not(:checked) + label:before,
    &[type="checkbox"]:disabled:checked + label:before {
      box-shadow: none;
      border-color: $color-grey-mid;
      background-color: $color-grey-light;
    }

    &[type="checkbox"]:disabled:checked + label:after {
      color: $color-grey-dark;
    }

    &[type="checkbox"]:disabled + label {
      color: $color-grey-mid;
    }

    /* Accessibility */
    &[type="checkbox"]:checked:focus + label:before,
    &[type="checkbox"]:not(:checked):focus + label:before {
      outline: 5px auto Highlight;
      outline: 5px auto -webkit-focus-ring-color;
    }
  }
}
