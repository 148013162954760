@import "../../styles/global";

.DevBlogPost {
  background-color: $color-background;
  padding: gap-size();
  margin-bottom: gap-size();
  box-shadow: 0px 2px 10px #00000026;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  img {
    width: 12rem;
    height: 100%;
    max-height: 7rem;
    margin-right: 2rem;
  }

  &__content {
    p {
      margin-bottom: 0;
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }

  &__title {
    color: $color-primary-dark;
    font-size: 1.3rem;
    font-weight: 500;
  }

  &__link {
    flex: 1;
    color: $color-text-dark;
    transition: color ease-in-out 0.2s;

    &:hover {
      color: $color-primary-dark;
      text-decoration: none;
    }
  }
}
