@import "../../../styles/global";

.ebay-shipping-services {
  &__row {
    display: flex;
    padding: gap-size(0.25);

    &:nth-of-type(odd) {
      background-color: #ededed;
    }
    & :not(:last-of-type) {
      margin-right: gap-size(0.5);
    }
  }
  &__title {
    font-size: $font-size-small;
    flex-basis: 30rem;
  }
  &__change-order {
    background: unset;
    border: unset;
    color: $color-primary;
  }

  &__add-button {
    margin-top: gap-size();
  }
}
