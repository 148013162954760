@import "../../../styles/global";

.ebay-error {
  border: 1px solid $color-error;
  background-color: $color-error-background;
  padding: gap-size();
  margin-bottom: gap-size();

  &__title {
    color: $color-error;
    font-weight: $font-weight-semi-bold;
  }
  &__message {
    font-size: $font-size-small;
  }
}
