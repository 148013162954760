@import "../../../styles/global";
@import "../../../styles/form";

.ebay-listing-template-form {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: gap-size(4);

  &__base-selector {
    margin-right: gap-size(2);
    flex-basis: 13rem;
  }

  &__label {
    display: block;
  }

  &__select {
    @include select-styling;
  }

  &__editor {
    flex-grow: 1;
    flex-basis: 35rem;
    padding-right: gap-size(2);
    height: 100%;

    & > div {
      background: $color-background;
    }
  }
}
