// Events
img.eventBanner {
  max-width: 281px;
}
.newEvent {
  .btn1 {
    width: max-content;
    padding: 0 22px;
    min-width: 160px;
    float: right;
    position: relative;
    bottom: 29px;
  }
  .conditionSelector span {
    background: #4d4f5c;
    display: inline-block;
    margin: 0 7px;
    box-shadow: 0px 2px 6px #0000000a;
    border-radius: 5px;
    text-align: center;
    font-family: "Source Sans Pro", sans-serif;
    color: white;
    cursor: pointer;
    &.active {
      background: #00bdbc;
    }
  }
  .revised {
    p.subHeading {
      color: #4d4f5c;
      margin-bottom: 1px;
      margin-top: 10px;
      font-family: "Source Sans Pro", sans-serif;
      border-top: 1px solid #dad6d6;
      padding-top: 4px;
      margin-top: 11px;
    }
  }
  .card-content.content .variants input {
    margin-bottom: 28px;
  }
  .rulesWrapper {
    margin: 21px;
    margin-bottom: 40px;
    .rarityWrapper {
      background: #f1f1f5;
      width: 100%;
      padding: 17px;
      p.subHeading.centered {
        color: #4d4f5c;
        font-family: "Source Sans Pro", sans-serif;
      }
    }
    .block {
      p.title {
        color: #4d4f5c;
        margin-bottom: 1px;
        margin-top: 10px;
        font-family: "Source Sans Pro", sans-serif;
      }
      p.help {
        color: #a3a4aa;
        font-size: 13px;
        font-family: "Source Sans Pro", sans-serif;
      }
      input {
        border: 1px solid #ebebf2;
        border-radius: 5px;
        width: 100%;
        padding-left: 16px;
      }
    }
    label.checkbox {
      color: #4d4f5c;
      font-size: 15px;
      .checkmark {
        top: -1px;
      }
    }
    .rulesBlock {
      display: none;
      &.active {
        display: block;
      }
    }
  }
}
.eventPartipants {
  table {
    thead tr {
      line-height: 20px;
    }
    tr {
      border-left: none;
    }
  }
}
.EventListing,
.filters,
.newEvent {
  &.update {
    margin-bottom: 50px;
    .EventBtn {
      margin-top: -37px;
    }
  }
  .field {
    label.label {
      color: #4d4f5c;
      font-size: 15px;
      margin-top: 9px;
      font-family: "Source Sans Pro", sans-serif;
    }
  }
  .topcontent {
    background: #00bdbc;
    padding: 15px 0px;
    span.icon.is-small.is-left {
      position: absolute;
      left: 23px;
      top: 37px;
      color: #4fbdbb;
    }
    label {
      letter-spacing: 0;
      color: #ffffff;
      font-family: "Source Sans Pro", sans-serif;
      font-size: 15px;
    }
    input {
      border: 1px solid #ebebf2;
      border-radius: 5px;
      width: 100%;
      padding-left: 15px;
      color: #4d4f5c;
      font-family: "Source Sans Pro", sans-serif;
      &:focus {
        outline: none;
      }
    }
  }
  #eventImages {
    label.label {
      margin-bottom: 0;
    }
    .uploadSection {
      background: white;
      padding-top: 21px;
      &.disabled {
        opacity: 0.3;
        cursor: not-allowed !important;
        pointer-events: none;
        background: gainsboro;
      }
      .imageWrapper {
        width: 100px;
        height: 100px;
        display: block;
        border: 1px solid #c1c1c1;
        border-radius: 6px;
        margin: 0px auto;
        margin-bottom: 20px;
        &.background img {
          opacity: 0.2;
        }
        img {
          max-width: 100px;
          max-height: 100px;
          text-align: center;
          width: 100%;
          margin: 0 auto;
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      label.custom-file-upload {
        margin-bottom: 0;
        width: 100%;
        height: 60px;
        color: white;
        line-height: 60px;
        text-align: center;
        display: block;
        background: #00bdbc;
        input {
          display: none;
        }
      }
    }
  }
  .eventInformation,
  #recurringSection,
  #eventImages,
  #ticketInfo,
  #additionalInfo {
    background: #f1f1f5;
    padding: 15px 0px;
    margin-bottom: 9px;
    #weeklySelection {
      padding-bottom: 22px;
      .weekSwatchWrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        .weekSwatches {
          padding: 0px 15px;
          .checkbox {
            padding-left: 0;
            margin-right: 36px;
            max-width: 110px;
            text-align: center;
            border: 1px solid #4fbdbb;
            color: #4fbdbb;
            padding: 1px 19px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            input:checked ~ .checkmark {
              background-color: #4fbdbb;
              border: 1px solid #4fbdbb;
            }
            .checkmark {
              top: -1px;
              right: -24px;
              left: unset;
              background-color: #4fbdbb;
              border: 1px solid #4fbdbb;
              border-radius: 0;
              height: 26px;
              width: 26px;
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
            }
          }
        }
      }
    }
    p.locationHelper {
      margin-bottom: 0;
      text-align: center;
      margin-top: 10px;
      color: #777880;
      font-family: "Source Sans Pro", sans-serif;
      font-size: 13px;
    }
    p.subHeading {
      letter-spacing: 0;
      color: #4d4f5c;
      font-size: 18px;
      font-family: "Source Sans Pro", sans-serif;
      margin-bottom: 7px;
    }
    label {
      letter-spacing: 0;
      color: #4d4f5c;
      font-family: "Source Sans Pro", sans-serif;
      font-size: 15px;
    }
    input {
      border: 1px solid #ebebf2;
      border-radius: 5px;
      width: 100%;
      padding-left: 15px;
      color: #4d4f5c;
      font-family: "Source Sans Pro", sans-serif;
      &:focus {
        outline: none;
      }
    }
    select {
      background: #00bdbc 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 6px #0000000a;
      border-radius: 5px;
      border: 1px solid #00bdbc;
      color: white;
      font-family: "Source Sans Pro", sans-serif;
      font-size: 15px;
      text-align: center;
      text-align-last: center;
      width: 100%;
      height: 26px;
      display: block;
      margin: 0 auto;
      cursor: pointer;
      background-position: right;
      padding: 2px 20px;
      line-height: 18px;
      option {
        text-align: center;
        text-align-last: center;
      }
      &.active,
      &:hover {
        background: #05a0a0;
        transition: all 1s;
        color: white;
        text-decoration: none;
      }
    }
    input#recurringFrequency {
      width: 68px;
      text-align: center;
      margin-left: 6px;
      padding-left: 0;
      display: inline-block;
    }
    select#recurringSelect {
      display: inline-block !important;
      width: calc(100% - 75px) !important;
      min-width: 86px;
    }
  }
  #ticketInfo,
  #additionalInfo {
    background: #fff;
    border: 1px solid #f1f1f5;
    div#additionalInfoSection {
      margin-bottom: 63px;
    }
    .card-content {
      background: #f1f1f5;
      height: 295px;
      padding: 13px;
      display: block;
      input {
        border: 1px solid #ebebf2;
        border-radius: 5px;
        padding-left: 5px;
      }
      textarea {
        border: 1px solid #ebebf2;
        border-radius: 5px;
        width: 100%;
        min-height: 71px;
        padding-left: 5px;
        resize: none;
        &:focus {
          outline: none;
        }
      }
      button.deleteInfo {
        position: absolute;
        right: 26px;
        background: none;
        color: #d0a4a4;
        border: none;
        cursor: pointer;
        top: 9px;
      }
      p {
        color: #a3a4aa;
        font-size: 13px;
        margin-bottom: 6px;
      }
      &.addNew {
        background: white;
        border: 1px solid #f1f1f5;
        span {
          letter-spacing: 0;
          color: #a3a4aa;
          width: 89px;
          display: block;
          text-align: center;
          margin: 0 auto;
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          i {
            display: block;
            color: #00bdbc;
            font-size: 38px;
            &.active,
            &:hover {
              color: #05a0a0;
              transition: all 1s;
              text-decoration: none;
            }
          }
        }
      }
    }
    .checkbox {
      .checkmark {
        background: #4d4f5c;
        border: none;
        top: -1px;
      }
      em {
        font-style: normal;
        padding-left: 15px;
        color: #9aa1ad;
      }
    }
    label.label {
      margin-bottom: 0;
    }
    p.textNorm {
      color: #a3a4aa;
      font-size: 13px;
      margin-bottom: 0;
    }
  }
  .EventBtn {
    background: #00bdbc;
    display: block;
    height: 66px;
    color: white;
    width: 100%;
    max-width: 413px;
    border: none;
    text-transform: uppercase;
    position: absolute;
    float: right;
    margin-right: 12px;
    margin-top: -73px;
    right: 60px;
    &.active,
    &:hover {
      background: #05a0a0;
      transition: all 1s;
      text-decoration: none;
    }
  }
}

.events-filter {
  font-size: 0.8em;
  .always-visible {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .btn1 {
      top: 0;
    }
  }

  .btn1 {
    margin-left: 0;
  }

  .reset-button {
    background-color: #00bdbc;
    border: #00bdbc;
    color: #fff;
    padding: 0.2em 0.5em;
    border-radius: 0.5em;
    // float: right;
    margin-top: 0.25em;
    margin-right: 0.25em;
  }

  .radio-group {
    input[type="radio"] {
      display: none;
    }
    input[type="radio"] + label {
      padding: 0.25em 1em;
      border: 1px solid #00bdbc;
      margin: 0;
      color: #999;
    }
    input[type="radio"]:checked + label {
      background-color: #00bdbc;
      color: #fff;
    }
    input[type="radio"]:not(:checked) + label:hover {
      color: #000;
      cursor: pointer;
    }
    .form-check-inline,
    .form-check-inline .form-check-input {
      margin: 0;
      padding: 0;
    }
    .form-check:nth-of-type(1) label {
      border-top-left-radius: 0.6em;
      border-bottom-left-radius: 0.6em;
    }
    .form-check:nth-of-type(3) label {
      border-top-right-radius: 0.6em;
      border-bottom-right-radius: 0.6em;
    }
  }
  .togglable-filters {
    background-color: #f1f1f1;
    max-height: 0;
    transition: max-height 1s;
    overflow-y: hidden;
    &.visible {
      max-height: 600px;
    }
    .form-group {
      display: grid;
      grid-auto-columns: max-content;
      align-items: baseline;
      grid-column-gap: 1em;
      grid-row-gap: 1em;
      padding: 1em;
      & > *:nth-child(odd) {
        grid-column: 1;
      }
      & > *:nth-child(even) {
        grid-column: 2;
      }
    }
    label {
      font-weight: bold;
    }

    .form-check-input {
      position: unset;
    }
    input[type="number"] {
      width: 5em;
    }
  }
}

@media screen and (max-width: 1199px) {
  .events-filter {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 649px) {
  .events-filter .togglable-filters .form-group {
    grid-auto-columns: 100%;

    & > *:nth-child(even) {
      grid-column: 1;
    }
  }
}

@media screen and (max-width: 400px) {
  .events-filter .radio-group {
    display: grid;
    grid-auto-columns: 100%;

    > div {
      width: 100%;

      label {
        width: 100%;
        border-top-right-radius: unset !important;
        border-top-left-radius: unset !important;
        border-bottom-right-radius: unset !important;
        border-bottom-left-radius: unset !important;
      }
    }
  }
}
