@import "../../../styles/form";

.ebay-provisioning-form {
  @include default-form;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: gap-size();
  }
}
