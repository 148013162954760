@import "../../../styles/global";

.ebay-description-editor {
  position: relative;
  background-color: $color-background;

  &__error {
    color: $color-error;
    background: $color-grey-mid;
    padding-left: gap-size();
  }

  &:hover {
    z-index: 20;
  }

  &__warning-text {
    color: $color-primary-dark;
    padding: gap-size(0.25);
  }
}
