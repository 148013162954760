.data-table {
  font-size: 1.1em;
  color: #4d4f5c;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 10px 3px #00000026;
  border: none !important;

  .negative {
    color: red;
  }

  .BaseTable {
    font-size: 15px;
  }

  .BaseTable__sort-indicator {
    color: #00bdbc;
  }

  i.fas,
  i.far {
    font-size: 1.2em;
    &:hover {
      cursor: pointer;
    }
  }

  .table-row-clickable {
    &:hover {
      cursor: pointer;
    }
  }

  .table-container {
    width: calc(100vw - 489px);
    height: calc(100vh - 375px);

    @media only screen and (max-width: 1365px) {
      width: calc(100vw - 120px);
    }

    @media only screen and (max-width: 1199px) {
      width: calc(100vw - 45px);
    }

    @media only screen and (max-width: 768px) {
      width: calc(100vw - 50px);
    }
  }

  .filters {
    select {
      width: 98%;
      height: 28px !important;
      font-family: "Source Sans Pro", sans-serif;
      color: grey;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
      padding: 5px 7px;
      border-radius: 3px;
      font-weight: normal;
      outline-width: 0;
    }

    input[type="text"] {
      width: 98%;
      height: 28px;
      font-family: "Source Sans Pro", sans-serif;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
      padding: 5px 7px;
      font-size: inherit;
      border-radius: 3px;
      font-weight: normal;
      outline-width: 0;
    }
  }

  .pagination {
    font-size: 0.8em;
    display: grid;
    grid-template-columns: repeat(4, 24.25%);
    grid-column-gap: 1%;

    select {
      height: 2em;
      margin-top: 3px;
    }

    @media only screen and (max-width: 599px) {
      grid-template-columns: repeat(3, 32.8%);
      select {
        display: none;
      }
    }

    button {
      display: block;
      border: 0;
      border-radius: 3px;
      padding: 6px;
      font-size: 1em;
      color: rgba(0, 0, 0, 0.6);
      background: rgba(0, 0, 0, 0.1);
      transition: all 0.1s ease;
      cursor: pointer;
      outline-width: 0;

      &:not(:disabled) {
        color: white;
        background-color: #00bdbc;
        &:hover {
          background-color: #0a162e;
          transition: background-color 0.4s;
        }
      }
    }

    .page-info {
      display: flex;
      justify-content: center;
      align-items: center;

      input {
        width: 3em;
        margin-left: 0.5em;
        margin-right: 0.2em;
      }
    }
  }
}

.filterable-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  > div {
    overflow: hidden;
    padding: 0 0.25em;
  }
  button {
    font-size: 0.8em;
  }
}
.BaseTable__header > div {
  min-width: 100% !important;
}
.BaseTable__header-row--customized:first-of-type {
  background-color: #4fbdbb;
  color: white;

  .BaseTable__header-cell--sortable:hover {
    background-color: #4ebcbc;
  }
  .BaseTable__sort-indicator {
    color: #ededed;
  }
}
