.basic-modal {
  &__container {
    max-width: unset !important;
    width: 60vw !important;
    overflow-y: auto;
  }

  &__contents {
    overflow-y: auto;
    height: calc(100% - 288px);
  }
}
