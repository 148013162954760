@import "../../../styles/button";

.button-component {
  @include button;

  display: inline-block;

  &--half-width {
    width: 50%;
  }

  &--full-width {
    width: 100%;
  }

  &--half-width,
  &--full-width {
    border-radius: 0;
    text-transform: uppercase;
    font-family: $font-family-secondary;
    font-size: $font-size-default;
    font-weight: $font-weight-normal;
    letter-spacing: 0.05rem;
    padding: gap-size();
  }
}
