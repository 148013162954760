.spinner-container {
  position: relative;

  .spinner-overlay {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.7);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
  }

  &.table-loader {
    width: 100%;
    height: 100%;
  }
}
