.store-logo {
  &__upload-button {
    background: #00bdbc;
    color: white;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 1em;
    text-align: center;
    border: none;
    border-radius: 5px;
    height: 2.5em;
    line-height: 2.5em;
    cursor: pointer;
    transition: all 1s;
    padding: 0 1em 0.25em;
  }
  input[type="file"] {
    display: none;
    &:before {
      display: none !important;
    }
  }

  &__description {
    font-size: 0.8em;
  }

  &__file-error {
    color: red;
  }

  &__image {
    width: 10em;
    height: 10em;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}
