@import "../../../styles/global";

.ebay-settings-list {
  &__entries {
    display: grid;
    grid-template-columns: auto 8em 8em;
    grid-column-gap: gap-size();
    grid-row-gap: gap-size(0.25);

    & > span {
      padding: gap-size(0.25);
    }

    & > *:nth-child(6n + 1) {
      background-color: #ededed;
    }
  }

  &__general-actions {
    margin-top: gap-size();
    line-height: 2.5;
    > * {
      margin-right: gap-size();
    }
  }
}
