@import "../../../styles/global";
@import "../../../styles/form";

.BulkUpdateModal {
  @include default-form;

  &__subSettings {
    margin-left: gap-size(0.7);
    padding-left: gap-size(1.3);
    border-left: $border-width-thick solid $color-primary;

    & label.checkbox {
      font-size: $font-size-default;
      padding-top: 0.3rem;
    }
  }
}
