@import "../../../styles/global";

.ebay-misc-setting {
  &__main {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    color: $color-primary-dark;
    font-size: $font-size-mid;
  }

  &__controls {
    & > * {
      margin-left: gap-size();
    }
  }
}
