@import "../../../styles/global";

.ActionButtonsLayout {
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin-bottom: gap-size(0.5);
  }

  & > *:not(:last-child) {
    margin-right: gap-size();
  }
}
