@import "../../../../styles/global";

.category-container {
  &__top {
    display: flex;
    flex-wrap: wrap;
  }

  &__current {
    flex: 1;
  }

  &__search {
    flex: 1;
  }

  &__search-label {
    padding-right: gap-size();
  }

  &__categories {
    margin-top: gap-size();
  }
}
