@import "../../../styles/global";

.integration-setting-form {
  font-size: $font-size-standard;

  // Shouldn't have to be doing this. Grrr
  label {
    margin-bottom: 0;
  }

  &__inputs {
    background-color: $color-grey-mid;
    padding: gap-size();
  }

  &__subheading {
    font-size: $font-size-mid;
    font-weight: $font-weight-normal;
  }

  &__unchangeable {
    font-size: $font-size-mid;
    color: $color-primary-dark;
  }

  &__criteria {
    display: flex;
    flex-wrap: wrap;
  }

  &__criterion {
    flex-grow: 1;
    min-width: 20rem;
    margin-right: gap-size();
    margin-bottom: gap-size();
    align-self: flex-end;
  }

  &__select {
    display: block;
    font-size: $font-size-small;
    background: $color-primary;
    color: $color-text-light;
    border-radius: $border-radius-standard;
    border: $border-width-fine solid $color-primary;
    text-align: center;
    text-align-last: center;
    width: 100%;
    height: gap-size(1.5);
    cursor: pointer;
  }

  &__update-warning {
    margin-top: gap-size(0.5);
    margin-bottom: gap-size(1);
    color: $color-primary-dark;
  }

  &__sync-settings {
    margin-top: gap-size(0.5);

    &--disabled {
      pointer-events: none;
      opacity: 0.4;
    }
  }

  &__common-settings {
    display: flex;
    flex-wrap: wrap;
  }

  &__field-block {
    align-self: flex-end;
    flex: 1 1 18%;
    margin-right: gap-size();
    min-width: 10rem;

    &--with-limit-text {
      position: relative;
      top: 1.3em;
    }
  }

  &__input {
    width: 100%;

    &--with-symbol {
      width: calc(100% - 1.5rem);
    }
  }

  &__help-text {
    font-size: $font-size-small;
    margin-bottom: 0;
  }

  &__limit-text {
    font-size: $font-size-small;
  }

  &__error {
    color: $color-error;
  }

  &__actions {
    margin-top: gap-size();
    display: flex;
    justify-content: flex-end;
  }

  &__apply-option {
    margin-left: gap-size();
  }

  &__apply-input {
    margin-right: gap-size(0.5);
  }
}
