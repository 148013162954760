@import "../../../styles/global";

.IconButton {
  width: max-content;
  padding: 0 gap-size();
  font-size: $font-size-mid;
  color: $color-primary;
  cursor: pointer;

  @include hover-focus-active {
    color: $color-primary-dark;
    transition: $transition-background;
    text-decoration: none;
  }

  &--secondary {
    color: $color-text-dark;
    @include hover-focus-active {
      color: $color-text-light;
    }
  }

  &--danger {
    color: $color-danger;
    @include hover-focus-active {
      color: $color-red;
    }
  }
}
