@import "../../../styles/global";

.ebay-custom-attributes {
  display: flex;
  flex-direction: column;
  width: calc(100% - 15rem);

  &__attribute {
    margin-bottom: gap-size(0.5);
  }

  &__key {
    display: inline-block;
  }

  &__key {
    width: 13rem;
  }

  &__value {
    width: 12rem;
    margin-right: 1rem;
  }

  &__new {
    margin-left: 15rem;
    margin-top: gap-size(0.5);
  }
}
