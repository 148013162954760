@import "../../../styles/global";

.cfb-form-error {
  color: $color-error;
  margin-top: 0.5rem;
  font-size: 0.9rem;
  ul {
    padding: 0;
    margin-left: calc(-15px + 1%);
    margin-right: calc(9% + 1em);
    text-align: center;
  }
  ul > li {
    list-style-type: none;
    margin: 0 0 0.5rem 0;
    padding: 0.25rem;
    background-color: rgba(lighten($color-error, 0.5), 0.1);
    border-radius: 0.25rem;
  }
}
