// Spacing
// -------------------------------------
@function gap-size($multiplier: 1) {
  // use a multiple of the default gap size for consistancy.
  @return ($gap-size * $multiplier);
}

// Pseudo class group mixins
// -------------------------------------

// Shortcut for hover, focus and active states
@mixin hover-focus-active {
  &:hover:not(:disabled),
  &:focus:not(:disabled),
  &:active:not(:disabled) {
    @content;
  }
}

// Shortcut for focus and active states
@mixin focus-active {
  &:focus,
  &:active {
    @content;
  }
}
