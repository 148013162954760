@import "../../../styles/global";

.MobileNavItem {
  &__header {
    padding-left: 2px;
    color: $color-text-light;
    font-size: 28px;
    border-bottom: 1px solid $color-text-light;
    margin-bottom: 6px;
    font-family: $font-family-secondary;
    text-decoration: none;

    &:not(:first-child) {
      margin-top: 17px;
      display: block;
    }

    &:hover {
      color: $color-text-light;
    }
  }

  &__subItem {
    padding-left: 34px;
    font-size: 22px;
    padding-top: 10px;

    &:last-child {
      margin-bottom: 50px;
    }
  }

  &__subItemLink {
    color: #e2e2e2;
    font-family: $font-family-secondary;
    border-left: 2px solid $color-text-light;
    padding-left: 11px;

    &:hover {
      color: #e2e2e2;
    }
  }

  @media screen and (max-width: 650px) {
    &__header:first-child {
      margin-top: 102px;
      display: block;
    }
  }
}
