@import "../../../styles/global";

.ebay-category-list-item {
  &__category {
    list-style: none;
    padding-bottom: gap-size(0.25);
  }

  &__selector {
    margin-right: gap-size(0.5) !important;
  }
}
