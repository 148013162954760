@import "../../../styles/form";

.ebay-payment-methods-form {
  @include default-form;

  &__payment-methods > * {
    display: block;
  }

  &__info-block {
    border: 2px solid $color-primary;
    padding: gap-size(0.25);
    color: $color-grey-dark;

    a {
      color: $color-text-link;
    }
  }
}
