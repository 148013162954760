.stock-change-info-view {
  &__title {
    padding-left: 0;
  }

  &__filters {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    font-family: "Source Sans Pro", sans-serif;
    color: #4d4f5c;
  }

  &__label {
    margin-right: 0.5rem;
  }

  &__results {
    margin: 1rem 0;
    > table {
      margin-bottom: 1rem;
    }
  }

  &__button {
    top: 0 !important;
    min-width: unset !important;
  }
}
