.paging {
  text-align: right;
  button {
    background: #4d4f5c;
    box-shadow: 0px 2px 6px #0000000a;
    border-radius: 5px;
    border: none;
    color: white;
    font-size: 15px;
    font-family: "Source Sans Pro", sans-serif;
    margin: 0 5px;
    padding: 0 20px;
    height: 24px;
  }
}

@media screen and (max-width: 650px) {
  .paging {
    padding-left: 13px;
  }
}

@media screen and (max-width: 1199px) {
  .paging {
    padding-left: 20px;
    padding-right: 20px;
  }
}
