@import "../../../styles/form";

.ebay-address-form {
  @include default-form;

  &__checkbox-label > input {
    margin-right: gap-size(0.25);
  }

  &__location-type > * {
    margin-right: gap-size();
  }
}
