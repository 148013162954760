.ToggleVariantsButton {
  float: right;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 0 1rem;
  min-width: 10rem;
  &:hover,
  &:focus,
  &:active {
    outline-color: #05a0a0;
  }
  & > i {
    color: #00bdbc;
  }
}
