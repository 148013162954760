.app-header .beta {
  font-size: 0.5em;
  color: #00bdbc;
  vertical-align: super;
  padding-left: 0.2em;
}

.reports-menu {
  &.row > div {
    margin-bottom: 1em;
  }
  .card {
    height: 100%;

    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.report {
  .report-buttons {
    display: flex;
    justify-content: space-between;
    select,
    input[type="date"],
    button {
      background: #00bdbc;
      color: white;
      font-family: "Source Sans Pro", sans-serif;
      font-size: 1em;
      text-align: center;
      border: none;
      border-radius: 5px;
      height: 2.5em;
      line-height: 2.5em;
      cursor: pointer;
      transition: all 1s;
      padding: 0 1em 0.25em;
      margin-bottom: 0.5em;
      i {
        margin-right: 0.5em;
      }

      &.full-width {
        width: 100%;
        display: block;
      }
    }
    .export button {
      margin-left: 0.5em;
    }
    .date-range-select {
      select,
      input {
        margin-right: 0.5em;
      }
      select,
      input {
        padding: 0 0.2em;
        &::-webkit-calendar-picker-indicator {
          filter: invert(1);
        }
      }
      ::-webkit-datetime-edit-fields-wrapper {
        height: 2.4em;
      }
    }
    .checkbox {
      background: #00bdbc;
      color: white;
      font-family: "Source Sans Pro", sans-serif;
      font-size: 1em;
      text-align: center;
      border: none;
      border-radius: 5px;
      height: 2.5em;
      line-height: 2.5em;
      cursor: pointer;
      transition: all 1s;
      padding: 0.4em 1em 0.25em;
    }
  }
  .data-table {
    .BaseTable {
      font-size: 0.7em;
    }
    .button {
      background: #00bdbc;
      color: white;
      font-family: "Source Sans Pro", sans-serif;
      font-size: 1em;
      text-align: center;
      border: none;
      border-radius: 5px;
      height: 2.5em;
      line-height: 2.5em;
      cursor: pointer;
      transition: all 1s;
      padding: 0 1em 0.25em;
      margin-bottom: 0.5em;
      i {
        margin-right: 0.5em;
      }
      &:hover {
        background-color: #00a3a3;
      }
    }
  }
  .buylist-report-link a {
    color: #4ebcbc;
  }
  .info {
    font-size: 0.9em;
    border: 1px solid #4ebcbc;
    background-color: rgba(192, 192, 192, 0.15);
    margin-top: -1em;
    margin-bottom: 1em;
    padding: 0.5em;
    border-radius: 5px;
    a {
      color: #008888;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.ViewNotes {
  &__content {
    min-height: 4em;
  }
  &__close-button {
    background: #00bdbc;
    color: white;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 1em;
    text-align: center;
    border: none;
    border-radius: 5px;
    height: 2.5em;
    line-height: 2.5em;
    cursor: pointer;
    transition: all 1s;
    padding: 0 1em 0.25em;
    margin-bottom: 0.5em;
  }
}

.LedgerReport {
  &__body {
    overflow-y: auto;
    margin-bottom: 1em;
  }

  &__column {
    padding: 0.25em 0.75em 0.25em 0.1em;
    line-height: normal;
    vertical-align: top;
    &--no-wrap {
      white-space: nowrap;
    }
  }

  &__notes {
    max-width: 20em;
  }
  &__close-button {
    background: #00bdbc;
    color: white;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 1em;
    text-align: center;
    border: none;
    border-radius: 5px;
    height: 2.5em;
    line-height: 2.5em;
    cursor: pointer;
    transition: all 1s;
    padding: 0 1em 0.25em;
    margin-bottom: 0.5em;
  }
}

@media only print {
  #root > div,
  #root > nav {
    display: none;
  }

  BODY {
    background-color: #fff !important;
    overflow-y: unset;
    overscroll-behavior-y: unset;
  }

  main#binderpos-app {
    position: unset;
    top: unset;
    left: unset;
    width: unset;
    height: unset;
    overflow: unset;

    &:before,
    &:after {
      content: unset;
    }
    &.hasSubMenu {
      left: unset;
      width: unset;
    }

    .app-content {
      margin: unset;
      border: unset;
      background: unset;
      box-shadow: unset;
    }

    .data-table {
      font-size: 1em;
      box-shadow: unset;

      .table-container {
        width: unset;
        height: auto;

        > div {
          overflow: unset;
          height: 999999px;
          width: unset;
        }
      }
    }

    button {
      display: none;
    }

    .report .report-buttons .date-range-select {
      input::-webkit-calendar-picker-indicator {
        filter: unset;
      }
    }

    .pagination {
      display: none;
    }

    .BaseTable__header-row--customized {
      background-color: #ccc;
      color: #000;
    }
    .BaseTable__header {
      height: 40px !important;
    }
    .BaseTable__header-row--customized:nth-of-type(2) {
      display: none;
    }

    .BaseTable__body {
      overflow: initial;
    }
  }
}
