.ebay-stock-change-info {
  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  &__item {
    display: flex;
  }
  &__label {
    font-weight: bold;
    margin-right: 1rem;
    color: #007575;
  }

  &__section--wide {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
  }

  &__image {
    flex-basis: 10rem;
  }

  &__listing {
    flex: 1;
    .ebay-stock-change-info__item {
      flex-direction: column;
    }
  }

  &__error-list {
    max-height: 30vh;
    overflow-y: scroll;
    border: 1px solid #007575;
    padding: 0.5rem;
    margin-bottom: 1rem;
  }

  &__error {
    margin-bottom: 1rem;
    color: #690000;
  }
  &__error-params > * {
    text-align: left !important;
  }
}
