@import "../../../styles/global";

.Logout {
  // Have to force to override _reboot.scss
  color: $color-text-light !important;
  &:hover {
    cursor: pointer;
    color: $color-grey-light;
  }
}
