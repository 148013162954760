.ResultsPagination {
  &__buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    & > button {
      margin: 0 1rem;
    }
  }

  &--empty {
    height: 2.7rem;
  }
}
