@import "../../../styles/global";

.ebay-delete-setting {
  &__actions {
    margin-top: gap-size();
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-column-gap: gap-size();
    line-height: 2.5;
  }
}
