@import "../../../styles/global";

.tenderPayment {
  &__remaining {
    display: flex;
    justify-content: center;
  }

  &__payRemainingButton {
    font-weight: $font-weight-semi-bold;
    font-size: $font-size-mid;
    text-align: center;
    border: none;
    border-radius: $border-radius-small;
    background: $color-primary;
    color: $color-text-light;
    cursor: pointer;
    padding: gap-size(0.5);

    @include hover-focus-active {
      background: $color-primary-dark;
      transition: $transition-background;
    }
  }

  &__error {
    display: flex;
    justify-content: center;
    font-style: italic;
    color: $color-error;
    margin-bottom: -1.5rem;
  }

  &__other {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: gap-size(2);
  }

  &__cashDenominations {
    display: flex;
    justify-content: center;
    margin-top: gap-size();
  }

  &__cashDenomination {
    font-weight: $font-weight-semi-bold;
    font-size: $font-size-mid;
    text-align: center;
    border: none;
    border-radius: $border-radius-small;
    background: $color-primary;
    color: $color-text-light;
    cursor: pointer;
    padding: gap-size(0.5);
    margin-right: gap-size(0.5);

    @include hover-focus-active {
      background: $color-primary-dark;
      transition: $transition-background;
    }
  }
}
