.OutOfStockWarning {
  &__product {
    display: flex;
    flex-wrap: wrap;
  }

  &__productTitle {
    flex-basis: 10rem;
    flex-grow: 1;
  }

  &__productImage {
    flex-basis: 10rem;
    & > img {
      width: 100%;
      padding-left: 1rem;
    }
  }
}
