@import "./integrations.scss";
// @import "./reports.scss"; Moved to bottom of this file
@import "./spinner.scss";
@import "./events.scss";
@import "./paging.scss";
@import "./spinner.scss";
@import "./data-table.scss";
@import "./pos-settings.scss";
@import "./buylist.scss";
@import "./data-table.scss";
@import "./settings.scss";

body {
  background-color: #f5f5f5 !important;
  overflow-y: hidden;
  overscroll-behavior-y: contain;
}

.userActions {
  cursor: pointer;
}

.show {
  display: block !important;
}

.centered {
  text-align: center;
}

.align-col-bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media only screen and (max-width: 992px) {
    justify-content: normal;
  }
}

//Scrollbars
::-webkit-scrollbar {
  width: 5px;
}

.inline {
  display: inline-block !important;
}

input#eventEndDate {
  position: relative;
  left: 10px;
  top: -15px;
}

/* Freshworks Popup */
div#freshworks-frame-wrapper {
  left: unset !important;
  right: 30px !important;
  bottom: 14px !important;
}

.freshwidget-container {
  z-index: 999999 !important;
}
.freshwidget-button {
  display: none !important;
  opacity: 0;
}

#freshwidget-frame {
  height: 90vh !important;
  max-height: 650px;
}

@media only screen and (max-width: 768px) {
  #freshwidget-frame {
    top: 100px !important;
    left: calc(50% - 281px);
    // border: 1px solid #333;
  }
}
@media only screen and (max-width: 767px) {
  #freshwidget-frame {
    left: 0;
    top: 100px !important;
    border: 1px solid #333;
  }
}

//Toasts
.Toastify__toast--info {
  background: #4fbdbb !important;
  border-radius: 5px;
  .Toastify__close-button {
    position: relative;
    top: 15px;
  }
}

.Toastify__toast-container--bottom-left {
  bottom: 3em !important;
}

.has-icons-left input {
  padding-left: 30px !important;
}
span.icon.is-small.is-left.blue {
  position: absolute;
  left: 23px;
  top: 37px;
  color: #ffffff;
}

//WYSIWYG
.control.editor {
  height: 113px;
  margin-bottom: 51px;
  .ql-toolbar.ql-snow {
    font-family: "Source Sans Pro", sans-serif;
    padding: 3px 8px;
    background: #f1f1f5;
    color: #4d4f5c !important;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00bdbc;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0a162e;
}

button:disabled,
button[disabled] {
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -ms-filter: blur(2px);
  -o-filter: blur(2px);
  filter: blur(2px);
  cursor: not-allowed;
  background: #5c5c5c !important;
}

// contextMenus
.react-contextmenu {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  color: #373a3c;
  font-size: 16px;
  margin: 2px 0 0;
  min-width: 160px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;

  &.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
    z-index: 9999;
  }
}

.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: #373a3c;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: 3px 20px;
  text-align: inherit;
  white-space: nowrap;
  &.react-contextmenu-item--active,
  &.react-contextmenu-item--selected {
    color: #fff;
    background-color: #00bdbc;
    border-color: #00bdbc;
    text-decoration: none;
  }
  &.react-contextmenu-item--disabled {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
    color: #878a8c;
    &:hover {
      background-color: transparent;
      border-color: rgba(0, 0, 0, 0.15);
      color: #878a8c;
    }
  }
}
.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;
  &:hover {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
  }
}
.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
  > .react-contextmenu-item:after {
    content: "▶";
    display: inline-block;
    position: absolute;
    right: 7px;
  }
}
.example-multiple-targets::after {
  content: attr(data-count);
  display: block;
}

//Switcher
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  input:checked + .slider {
    background-color: #59bb6f;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #4d4f5c;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &.round {
      border-radius: 34px;
      &:before {
        border-radius: 50%;
      }
    }
    &:before {
      position: absolute;
      content: "";
      height: 28px;
      width: 28px;
      left: 0px;
      bottom: 0px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
}
///////////// Generic Styling/////////////////
//checkboxes
.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  em {
    font-style: normal;
    padding-left: 15px;
    color: #0a162e;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .checkmark {
      background-color: #4d4e5c;
      border: none;
    }
    &:checked ~ .checkmark:after {
      opacity: 1;
    }
  }
  .checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid #dadade;
    border-radius: 5px;

    &:after {
      content: "";
      position: absolute;
      opacity: 0;
      left: 9px;
      top: 7px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  &:hover input ~ .checkmark {
    border: 3px solid #dadade;
    &:after {
      content: "";
      opacity: 1;
      left: 7px;
      top: 5px;
      width: 5px;
      height: 10px;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

h4 {
  letter-spacing: 0;
  color: #4d4f5c;
  font-size: 13px !important;
  margin-bottom: 0;
  font-family: "Source Sans Pro", sans-serif;
  line-height: 0px !important;
}
h3 {
  font-family: "Source Sans Pro", sans-serif;
  text-align: left;
  letter-spacing: 0;
  color: #4d4f5c;
  font-size: 18px !important;
}
h2 {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 28px !important;
  letter-spacing: 0;
  color: #0a162e;
  padding-bottom: 21px;
  padding-top: 15px;
}
.btn-primary {
  background: #00bdbc;
  border-color: #00bdbc;
  &:not(:disabled):not(.disabled):active,
  &:hover {
    background: #00adac;
    border-color: #00adac;
  }
}
.btn1 {
  background: #00bdbc;
  color: white;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 15px;
  width: 100%;
  display: block;
  text-align: center;
  border: none;
  border-radius: 5px;
  height: 26px;
  line-height: 26px;
  cursor: pointer;
  transition: all 1s;
  &.jobQueue {
    position: absolute;
    top: 109px;
    max-width: 250px;
    right: 280px;
  }
  i {
    padding-left: 2px;
  }
  &.active,
  &:hover {
    background: #05a0a0;
    transition: all 1s;
    color: white;
    text-decoration: none;
  }
  &.decline {
    margin-right: 15px;
    background: #bd6a6a;
    &:hover {
      background: #ab4747;
    }
  }
  &.cancel {
    margin-right: 15px;
    background: #9c9999;
    &:hover {
      background: grey;
    }
  }
}

button.buttonWide,
.button.buttonWide {
  background: #00bdbc;
  color: white;
  height: 60px;
  display: block;
  line-height: 60px;
  text-align: center;
  margin: 35px 0px;
  i {
    padding-right: 5px;
  }
  &.active,
  &:hover {
    background: #05a0a0;
    transition: all 1s;
    color: white;
    text-decoration: none;
  }
}

.btn2 {
  background: #4d4f5c;
  color: white;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 15px;
  width: 130px;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 22px;
  text-align: center;
  border-radius: 5px;
  height: 26px;
  line-height: 26px;
  cursor: pointer;
  transition: all 1s;
  position: relative;
  top: 4px;
  i {
    padding-left: 2px;
  }
  &.active {
    background: #00bdbc;
  }
  &:hover {
    background: #05a0a0;
    transition: all 1s;
    color: white;
    text-decoration: none;
  }
}

.text-bold {
  font-weight: 600;
}
.hidden,
.hideItem {
  display: none !important;
}

.watermark {
  position: fixed;
  bottom: -302px;
  right: -182px;
  z-index: -1;
  pointer-events: none;
}

.watermarkPos1 {
  position: fixed;
  bottom: -270px;
  right: -182px;
  z-index: -1;
  pointer-events: none;
}

.watermarkPos2 {
  position: fixed;
  top: -382px;
  left: -661px;
  z-index: -1;
  transform: rotate(211deg);
  pointer-events: none;
  img {
    width: 200%;
  }
}

//Custom File Uploader
label.file-upload {
  background: #00bdbc;
  color: white;
  box-shadow: 0px 2px 6px #0000000a;
  border-radius: 5px;
  position: absolute;
  z-index: 9;
  margin-top: 3px;
  transition: all 1s;
  cursor: pointer;
  &.active,
  &:hover {
    background: #05a0a0;
    transition: all 1s;
    color: white;
    text-decoration: none;
  }
  i {
    padding: 0 10px;
  }
}
input[type="file"] {
  height: 29px;
  padding-left: 54px;
  &:before {
    content: "";
    background: white;
    position: absolute;
    width: 90px;
    height: 31px;
  }
  &:focus {
    outline: none;
  }
}
///

//modal
.modal {
  align-items: center;
  &.is-active,
  &.display-block {
    background: rgba(10, 22, 46, 0.8);
    z-index: 999999;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  &.loadCart {
    table {
      tr {
        border-left: none;
        cursor: pointer;
        line-height: 26px !important;
        &:hover {
          color: #5f5d5d;
        }
        i.fas.fa-save {
          padding-left: 10px;
        }
        &.saved {
          i.fas.fa-save {
            color: #5f5d5d;
          }
        }
      }
      th {
        text-align: left;
      }
    }
  }
  form {
    margin-bottom: 0;
  }
  .modal-card {
    border-top: 8px solid #00bdbc;
    border-bottom: 8px solid #00bdbc;
    background: url(../assets/img/modalWatermark.png), rgba(236, 245, 245, 0.9);
    margin: 0 auto;
    position: relative;
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 42%;
    width: 50vw;
    max-width: 700px;
    max-height: 87vh;
    padding: 45px;
    .modal-card-head {
      .modal-card-title {
        color: #0a162e;
        font-size: 28px;
        &--desktop {
          @media screen and (max-width: 651px) {
            display: none;
          }
        }
        &--mobile {
          @media screen and (min-width: 650px) {
            display: none;
          }
        }
      }
      button.delete {
        position: absolute;
        top: 52px;
        right: 45px;
        background: none;
        border: none;
        color: #4d4f5c;
        font-size: 15px;
        &.active,
        &:hover {
          color: #05a0a0;
          transition: all 1s;
          outline: none;
        }
        i {
          font-size: 25px;
          position: absolute;
          left: -16px;
        }
        i.fal.fa-door-open {
          left: -30px;
        }
      }
    }
    .modal-card-body {
      max-height: 65vh;
      overflow-y: auto;
      background: white;
      box-shadow: 0px 2px 10px #00000026;
      padding: 40px 30px;
      color: #4d4f5c;
      font-family: "Source Sans Pro", sans-serif;
      .additionalInput {
        float: right;
      }
      &.color {
        min-height: 358px;
        p {
          color: #4d4f5c;
          font-family: "Source Sans Pro", sans-serif;
          font-weight: 600;
        }
        input[type="text"] {
          border: 1px solid #ebebf2;
          border-radius: 5px;
          font-family: "Source Sans Pro", sans-serif;
          width: calc(100% - 438px);
          border: 1px solid #ebebf2;
          color: #4d4f5c;
          padding-left: 15px;
          position: absolute;
          right: 314px;
          &:focus {
            outline: none;
          }
        }
      }
      .sketch-picker {
        position: absolute;
        right: 75px;
        top: 131px;
      }
      .content {
        img.eventImage {
          vertical-align: middle;
          border-style: none;
          width: 80px;
          height: 80px;
          border-radius: 50px;
          background: white;
        }
      }
      .refresh {
        position: absolute;
        top: 103px;
        right: 87px;
        background: #00bdbc;
        color: white;
        width: 96px;
        height: 64px;
        text-align: center;
        line-height: 88px;
        cursor: pointer;
        &.active,
        &:hover {
          background: #05a0a0;
          transition: all 1s;
        }
      }
      p {
        color: #4d4f5c;
        font-family: "Source Sans Pro", sans-serif;
      }
      pre {
        color: #4d4f5c;
        font-family: "Source Sans Pro", sans-serif;
        text-align: center;
      }
      .wrapper {
        padding: 8px;
        overflow-x: hidden;
        .ReactTable {
          margin: 0;
          .rt-tbody {
            margin-top: 0px;
          }
          .rt-tr-group {
            padding: 7px 17px;
            margin: 0;
            i {
              position: relative;
              top: 6px;
            }
            i.fas.fa-cloud-download-alt {
              cursor: pointer;
              &.active,
              &:hover {
                color: #05a0a0;
                transition: all 1s;
                text-decoration: none;
              }
            }
            i.far.fa-times {
              cursor: pointer;
              color: #ec9898;
              &.active,
              &:hover {
                color: #bd4b4b;
                transition: all 1s;
                text-decoration: none;
              }
            }
            &:nth-child(odd) {
              background: #fff;
            }
          }
          .rt-th {
            border-right: none;
          }
          .rt-thead .rt-td {
            border-right: none;
          }
          .rt-thead.-filters {
            height: 40px;
            select {
              height: 28px !important;
              font-family: "Source Sans Pro", sans-serif;
              color: grey;
            }
            input[type="text"] {
              height: 28px;
              font-family: "Source Sans Pro", sans-serif;
            }
          }
          .rt-thead.-header {
            background: #4fbdbb;
            height: 39px;
            .rt-resizable-header-content {
              color: white !important;
              line-height: 6px !important;
              font-family: "Source Sans Pro", sans-serif !important;
            }
          }
        }
        &.reviewInputs {
          .checkbox {
            font-size: 19px;
            .checkmark {
              top: 2px;
            }
          }
          textarea {
            width: 100%;
            height: 106px;
            border: 1px solid #4fbdbb;
            resize: none;
            border-radius: 5px;
            padding: 5px;
          }
          textarea#approveReason {
            margin-bottom: 10px;
          }
          .conditionListing {
            line-height: 48px;
          }
          table {
            span {
              display: block;
              margin-bottom: 6px;
              &:last-child {
                margin-bottom: 0px;
              }
            }
          }
          .btn1 {
            position: relative;
            top: 11px;
          }
          p.subHeading {
            color: #ababab;
            font-size: 13px;
            margin: 0;
            margin-bottom: 10px;
          }
          input[type="text"],
          input[type="number"] {
            border: 1px solid #ebebf2;
            border-radius: 5px;
            font-family: "Source Sans Pro", sans-serif;
            width: 100%;
            border: 1px solid #ebebf2;
            color: #4d4f5c;
            padding-left: 15px;
            &:focus {
              outline: none;
            }
          }
          select {
            background: #00bdbc 0% 0% no-repeat padding-box;
            box-shadow: 0px 2px 6px #0000000a;
            border-radius: 5px;
            border: 1px solid #00bdbc;
            color: white;
            font-family: "Source Sans Pro", sans-serif;
            font-size: 15px;
            text-align: center;
            text-align-last: center;
            width: 100%;
            max-width: 206px;
            height: 26px;
            margin-bottom: 15px;
            display: block;
            cursor: pointer;
            background-position: right;
            padding: 2px 20px;
            line-height: 18px;
            option {
              text-align: center;
              text-align-last: center;
            }
            &.active,
            &:hover {
              background: #05a0a0;
              transition: all 1s;
              color: white;
              text-decoration: none;
            }
          }
        }
        label.label {
          color: #4d4f5c;
          display: block;
          margin-bottom: 4px;
        }
        .variant {
          margin-bottom: 18px;
        }
        table {
          margin-bottom: 0;
          .info {
            text-align: center;
          }
          thead {
            border-top: 2px solid #4fbdbb;
            tr {
              line-height: 15px;
              text-align: center;
            }
          }
          tbody {
            tr {
              line-height: 12px;
            }
          }
        }
      }
      .title {
        letter-spacing: 0;
        color: #4d4f5c;
        font-size: 15px;
        font-weight: 600;
        font-family: "Source Sans Pro", sans-serif;
      }
      .desc {
        letter-spacing: 0;
        color: #a3a4aa;
        font-size: 15px;
        font-family: "Source Sans Pro", sans-serif;
      }
    }
    .modal-card-foot {
      a.modalBtn {
        background: #00bdbc;
        width: 100% !important;
        display: block;
        text-align: center;
        line-height: 60px;
      }
      button.modalBtn,
      a.modalBtn {
        height: 60px;
        width: 50%;
        outline: none;
        border: none;
        color: white;
        font-size: 15px;
        font-family: "Source Sans Pro", sans-serif;
        text-transform: uppercase;
        i {
          font-size: 22px;
          position: relative;
          top: 3px;
          padding: 0 5px;
        }
        &.cancel {
          background: #0a162e;
          &.active,
          &:hover {
            background: #091223;
            transition: all 1s;
            color: white;
            text-decoration: none;
          }
        }
        &.action {
          background: #00bdbc;
          &.active,
          &:hover {
            background: #05a0a0;
            transition: all 1s;
            color: white;
            text-decoration: none;
          }
        }
        &.caution {
          background: #b02323;
          &.active,
          &:hover {
            background: #641212;
            transition: all 1s;
            color: white;
            text-decoration: none;
          }
        }
        &.full {
          width: 100%;
        }
      }
    }
    &.modal-card-large {
      width: 80vw;
      max-width: 1500px;
      max-height: 87vh;
      padding: 45px;
    }
    &.receiptSize {
      width: 80vw;
      max-width: 460px;
      max-height: 100vh;
      padding: 45px;
      color: 000;
      overflow: auto;
      .modal-card-body {
        padding: 0px;
        color: 000;
        height: auto;
        .receipt {
          padding: 16px;
          span.name {
            letter-spacing: 0;
            font-size: 20px;
            display: block;
            text-align: center;
            font-family: "Source Sans Pro", sans-serif;
          }
          span.giftReceipt {
            letter-spacing: 0;
            font-size: 20px;
            display: block;
            text-align: center;
            font-family: "verdana";
            color: #000 !important;
            font-weight: 600;
          }
          span.details,
          span.taxNumber {
            font-family: "Source Sans Pro", sans-serif;
            font-size: 13px;
            display: block;
            text-align: center;
          }
          span.taxNumber {
            font-weight: 600;
          }
          span.saleId,
          span.dateTime,
          span.subTotal,
          span.GST,
          span.total {
            display: block;
            font-family: "Source Sans Pro", sans-serif;
            font-size: 13px;
            margin-top: 5px;
            &.smaller {
              font-size: 11px;
            }
            em {
              font-style: normal;
              float: right;
            }
          }
          span.total {
            font-weight: 600;
          }
          span.itemTotalCount {
            text-align: center;
            display: block;
            font-family: "Source Sans Pro", sans-serif;
            font-size: 11px;
          }
          hr {
            margin-top: 12px;
            margin-bottom: 2px;
          }
          table {
            color: 000;
            box-shadow: none;
            tr {
              border-left: none;
              color: 000;
              td.qty {
                width: 36px;
                text-align: center;
                vertical-align: top;
                font-weight: 600;
                font-family: "Source Sans Pro", sans-serif;
                line-height: 51px;
              }
              td.itemTitle {
                padding-left: 8px;
                line-height: 18px;
                font-family: "Source Sans Pro", sans-serif;
                height: 54px;
                overflow: hidden;
              }
              td.price {
                text-align: right;
                line-height: 26px;
                font-family: "Source Sans Pro", sans-serif;
                em {
                  display: block;
                  font-style: normal;
                  font-weight: 600;
                }
              }
            }
          }
        }
      }
    }
    &:before {
      content: "";
      background: #00bdbc;
      position: absolute;
      width: 164px;
      height: 29px;
      top: -36px;
      left: 0px;
    }
    &:after {
      content: "";
      position: absolute;
      border-top: 29px solid transparent;
      border-bottom: 0px solid transparent;
      border-left: 69px solid #00bdbc;
      top: -36px;
      left: 164px;
    }
  }
}

//tables
table {
  width: 100%;
  background: white;
  box-shadow: 0px 2px 6px #00000059;
  tr {
    line-height: 62px;
    font-family: "Source Sans Pro", sans-serif;
    color: #4d4f5c;
    font-size: 300;
    font-size: 15px;
    border-bottom: 1px solid #e2e3e6;
    border-left: 3px solid #4fbdbb;
  }
  td {
    &.actions a {
      color: #a3a4aa;
      padding: 0 3px;
    }
    &.blue {
      border-left: 3px solid #8582dc;
      padding-right: 29px;
    }
    &.red {
      border-left: 3px solid #f18a80;
      padding-right: 29px;
    }
  }
  thead {
    background: #00bdbc;
    height: 38px;
    line-height: 38px;
    a.tableLink {
      letter-spacing: 0;
      color: #ffffff;
      font-weight: 600;
      font-family: "Source Sans Pro", sans-serif;
      text-align: right;
      width: 100%;
      display: block;
      padding-right: 23px;
      text-decoration: none;
      &.active,
      &:hover {
        color: #0a162e;
        transition: all 1s;
        text-decoration: none;
      }
      i {
        padding-left: 5px;
        font-size: 20px;
        position: relative;
        top: 2px;
      }
    }
    tr {
      line-height: 38px;
      font-family: "Source Sans Pro", sans-serif;
      font-size: 15px;
      font-weight: 600;
      color: white !important;
    }
  }
}
.bottom-pagination {
  top: 19px;
  position: relative;
  padding-bottom: 22px;
  button.paging-nav {
    background: #4d4f5c;
    border: none;
    height: 26px;
    width: 26px;
    margin: 0 2px;
    color: white;
    border-radius: 5px;
  }
}
//Pagination
.top-pagination {
  top: 19px;
  float: right;
  position: relative;
  button.paging-nav {
    background: #4d4f5c;
    border: none;
    height: 26px;
    width: 26px;
    margin: 0 2px;
    color: white;
    border-radius: 5px;
    &.large {
      width: 75px;
      font-size: 15px;
    }
  }
}

//React Tables
.ReactTable {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 10px 3px #00000026;
  margin: 21px;
  border: none !important;
  .rt-thead {
    .rt-tr {
      margin: 0px 17px;
    }
  }
  .rt-thead.-header {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
    .rt-resizable-header {
      padding: 10px 0px;
      .rt-resizable-header-content {
        font-family: "Source Sans Pro", sans-serif;
        letter-spacing: 0;
        color: #4d4f5c;
        font-size: 15px;
        text-align: left;
        padding: 8px 0px;
      }
      &.-sort-asc {
        box-shadow: none;
        .rt-resizable-header-content:after {
          content: "\f0de";
          font-family: "Font Awesome 5 Pro";
          font-weight: 900;
          margin-top: 18px;
          position: relative;
          top: 5px;
          padding-left: 5px;
        }
      }
      &.-sort-desc {
        box-shadow: none;
        .rt-resizable-header-content:after {
          content: "\f0dd";
          font-family: "Font Awesome 5 Pro";
          font-weight: 900;
          margin-top: 18px;
          position: relative;
          top: -2px;
          padding-left: 5px;
        }
      }
    }
  }
  .rt-tbody {
    color: #7f7d91;
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: 0;
    margin-top: 36px;
    overflow: hidden !important;
    .rt-tr-group {
      border-bottom: solid 1px rgba(0, 0, 0, 0.05);
      margin: 7px 17px;
    }
  }
  .rt-tfoot {
    position: -webkit-sticky;
    position: sticky;
    z-index: 1;
    bottom: calc(100% - 91px);
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: 0;
    font-weight: 600;
    font-size: 15px;
    background: #00bdbc;
    .rt-tr {
      margin: 0px 17px;
      .rt-td {
        color: white;
        border-right: none !important;
      }
    }
  }
}

//Chart Styles
.chartWrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 10px #00000026;
  padding: 18px 22px;
  font-family: "Source Sans Pro", sans-serif;
  p.subheading {
    letter-spacing: 0;
    color: #43425d;
    opacity: 0.5;
    border-bottom: 1px solid #acb4c3;
    padding-bottom: 15px;
    margin-bottom: 35px;
  }
  p.data {
    text-align: left;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0;
    color: #4d4f5c;
    margin-bottom: 13px;
    span {
      position: absolute;
      right: 39px;
      &.positive {
        color: #00bdbc;
        &:before {
          content: "\f0d8";
          font-family: "Font Awesome 5 Pro";
          position: absolute;
          left: -24px;
          top: 5px;
        }
      }
      &.negative {
        color: #4d4f5c;
        &:before {
          content: "\f0d7";
          font-family: "Font Awesome 5 Pro";
          position: absolute;
          left: -24px;
          top: 3px;
        }
      }
    }
  }
}
.brand {
  padding-left: 20px;
  padding-top: 16px;
  width: 202px;
  position: relative;
  z-index: 10000;
  pointer-events: none;
  img {
    width: 100%;
  }
}

.topCnrBg {
  position: absolute;
  z-index: 10000;
  background: white;
  width: 504px;
  height: 155px;
  box-shadow: 1px 1px 5px #92a9a8;
  transform: rotate(-18deg);
  left: -34px;
  top: -74px;
  pointer-events: none;
}

.company {
  position: absolute;
  transform: rotate(-18deg);
  top: 62px;
  left: 72px;
  width: 319px;
  text-align: center;
  font-family: "Source Sans Pro", sans-serif;
  color: #4fbdbb;
  z-index: 10000;
  font-size: 13px;
  pointer-events: none;
}
// App Sidebar
.app-sidebar {
  width: 244px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  .POSangle {
    position: fixed;
    z-index: 3;
    background: rgba(255, 255, 255, 0.7);
    width: 504px;
    height: 155px;
    transform: rotate(45deg);
    left: -271px;
    bottom: 0;
    z-index: 1;
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
  }
  .topCnrBgLight {
    border-top: 0px solid transparent;
    border-bottom: 135px solid transparent;
    border-left: 1019px solid #00bdbc;
    position: absolute;
    left: 64px;
    display: block;
    top: -7px;
    z-index: 2;
  }
  .leftBgBlur {
    background: #f1f1f1 0% 0% no-repeat padding-box;
    opacity: 0.95;
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    height: 3000px;
    position: absolute;
    right: 267px;
    top: -8px;
    width: 1000px;
    z-index: -3;
    transform: rotate(9deg);
  }
  .leftBgAngle {
    background: #4fbdbb;
    height: 3000px;
    position: absolute;
    right: -94px;
    top: 151px;
    width: 1000px;
    z-index: -4;
    transform: rotate(-12deg);
  }
}

// App TopNav
nav#main-nav {
  height: 85px;
  left: 0;
  top: 0;
  width: 100vw;
  background: url(../assets/img/watermarkMenu.png), #0a162e;
  z-index: 999;
  display: block;
  position: unset;
  background-repeat: no-repeat;
  background-position-x: 202;
  background-position: right;
  position: absolute;
  &.mobile {
    z-index: 999998;
  }
  .userSettings {
    position: fixed;
    top: 55px;
    right: 108px;
    width: 184px;
    color: #4fbdbb;
    opacity: 1;
    transition: all 1s;
    height: 62px;
    .menu {
      position: absolute;
      right: 17px;
      color: white;
      span {
        cursor: pointer;
        font-family: "Source Sans Pro", sans-serif;
        i {
          padding-right: 4px;
        }
      }
    }
    &.hidden {
      opacity: 1;
      z-index: 1;
      transition: all 1s;
      right: -590px;
    }
  }
  .pull-right {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    height: 85px;
    a.settingLink {
      position: absolute;
      right: 15px;
      top: 50%;
      z-index: 9;
      color: white;
      font-size: 23px;
      text-shadow: 1px 0px 0px #01bdbc;
      transform: translateY(-118%);
    }
    .avatar {
      width: 42px;
      height: 42px;
      border-radius: 39px;
      overflow: hidden;
      border: 2px solid #00bdbc;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 57px;
      img {
        width: 100%;
      }
    }
    .user {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 112px;
      color: #4fbdbb;
      font-size: 13px;
      text-align: right;
      width: 260px;
      user-select: none;
      .customer {
        .fa-user-headset,
        .fa-cogs,
        .fa-sign-out-alt {
          margin-right: 13px;
          color: white;
          font-size: 16px;
        }
        p,
        .supportlink {
          display: inline-block;
          margin: 0;
          cursor: pointer;
        }
        i {
          padding-left: 8px;
          display: inline-block;
        }
      }
    }
  }
}

// App Body
main#binderpos-app {
  position: fixed;
  left: 180px;
  top: 85px;
  width: calc(100vw - 180px);
  height: calc(100vh - 84px);
  z-index: 9999;
  overflow-x: hidden;
  overflow-y: auto;
  &.hasSubMenu {
    left: 368px;
    width: calc(100vw - 368px);
  }
  &:before {
    content: "";
    background: #0a162e;
    position: absolute;
    width: 164px;
    height: 29px;
    top: 46px;
    left: 22px;
  }
  &:after {
    content: "";
    position: absolute;
    border-top: 29px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 69px solid #0a162e;
    top: 46px;
    left: 186px;
  }
  .app-content {
    margin-right: 22px;
    border-top: 5px solid #0a162e;
    border-bottom: 5px solid #0a162e;
    background: rgba(255, 255, 255, 0.7) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    margin-left: 22px;
    margin-top: 75px;
    margin-bottom: 22px;
    section.section {
      padding: 11px 38px;
      .app-header {
        .btn1 {
          width: max-content;
          padding: 0 22px;
          min-width: 160px;
          float: right;
          position: relative;
          top: 4px;
          &.mobile {
            width: max-content;
            padding: 0 14px;
            min-width: unset;
            float: right;
            position: relative;
            top: 4px;
            i {
              line-height: 28px;
            }
          }
        }
        &.with-buttons {
          padding-top: 15px;
          padding-bottom: 21px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          h2 {
            padding-top: unset;
            padding-bottom: unset;
          }
          .btn1 {
            float: unset;
            top: unset;
            display: inline-block;
            margin-left: unset;
            margin-right: unset;
          }
          .buttons {
            padding-left: 15px;
            button {
              margin-bottom: 0.3em;
            }
            .btn1.cancel,
            .btn1.decline {
              margin-right: unset;
            }
          }
          .spaced-buttons {
            & > * {
              margin-left: 1rem;
            }
          }
          @media only screen and (max-width: 1199px) {
            padding-right: 20px;
          }
        }
      }
      .search {
        float: right;
        position: relative;
        top: 17px;
        margin-right: 21px;
        margin-left: 21px;
        i {
          color: #00bdbc;
          position: absolute;
          left: 11px;
          margin-top: 9px;
        }
        input#buylistSearch,
        input#EventSearch {
          border: 1px solid #00bdbc;
          border-radius: 14px;
          padding: 5px 0px 5px 36px;
          font-size: 13px;
          color: #4d4f5c;
          font-family: "Source Sans Pro", sans-serif;
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}

//DashBoard
#dashboard {
  .btn1 {
    margin-top: 21px;
    margin-bottom: 30px;
    i {
      padding-left: 3px;
    }
  }
  .wrapper {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 10px #00000026;
    padding: 22px;
    margin-bottom: 25px;
    .dashBoardFilter {
      margin-bottom: 18px;
      .css-9gakcf-option {
        background-color: #00bdbc;
      }
      .css-1pahdxg-control {
        background-color: #00bdbc;
        border-color: #00bdbc;
        box-shadow: 0 0 0 1px #00bdbc;
        color: #fff;
        .css-1uccc91-singleValue {
          color: hsl(0, 0%, 100%);
        }
        .css-1okebmr-indicatorSeparator {
          background-color: hsl(0, 0%, 100%);
        }
        .css-tj5bde-Svg {
          fill: #fff;
        }
      }
      .css-yk16xz-control {
        background-color: hsl(180, 50%, 55%);
        border-color: transparent;
        .css-1uccc91-singleValue {
          color: hsl(0, 0%, 100%);
        }
        .css-1okebmr-indicatorSeparator {
          background-color: hsl(0, 0%, 100%);
        }
        .css-tj5bde-Svg {
          fill: #fff;
        }
      }
    }
    h3 {
      font-size: 15px !important;
    }
    span.options {
      color: #00bdbc;
      position: absolute;
      right: 35px;
      top: 9px;
      transition: all 1s;
      cursor: pointer;
      &.active,
      &:hover {
        color: #05a0a0;
        transition: all 1s;
      }
    }
    .selectedRange {
      font-family: "Source Sans Pro", sans-serif;
      letter-spacing: 0;
      color: #a3a4aa;
      font-size: 15px;
      position: absolute;
      right: 34px;
      margin-bottom: 0;
      text-align: right;
    }
    p.statistic {
      font-family: "Source Sans Pro", sans-serif;
      letter-spacing: 0;
      color: #4d4f5c;
      font-weight: 600;
      font-size: 30px;
      border-bottom: 1px solid #d6d6d6;
      height: 81px;
      &.alt {
        border-bottom: none;
        height: 26px;
      }
    }
    .miniChart {
      width: 50%;
      position: absolute;
      right: 24px;
      top: -6px;
    }
    .subText {
      font-family: "Source Sans Pro", sans-serif;
      letter-spacing: 0;
      color: #a3a4aa;
      font-size: 15px;
      margin-bottom: 0;
      &.view {
        color: #00bdbc;
        position: absolute;
        right: 32px;
        top: 145px;
        &.active,
        &:hover {
          color: #05a0a0;
          transition: all 1s;
        }
      }
      .liveBadge {
        background: #00bdbc;
        color: white;
        font-family: "Source Sans Pro", sans-serif;
        font-size: 15px;
        padding: 0 4px;
        margin-right: 4px;
        i {
          padding-right: 2px;
        }
      }
    }
  }
}

img.failedImg {
  width: 100%;
}

//Tiled Flex Panels
.tiledPanels {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  .panelWrapper {
    max-width: 386px;
    display: inline-flex;
    overflow: hidden;
    margin: 15px;
    box-shadow: 0px 2px 10px #00000026;
    span.failedEdit {
      display: inline-block;
      width: 50%;
      background: #01bdbc;
      color: white;
      text-align: center;
      height: 39px;
      line-height: 39px;
      margin-top: 10px;
      cursor: pointer;
      &.active,
      &:hover {
        background: #05a0a0;
        transition: all 1s;
        color: white;
        text-decoration: none;
      }
    }
    span.failRemove {
      display: inline-block;
      width: 50%;
      background: #0a162e;
      color: white;
      text-align: center;
      height: 39px;
      line-height: 39px;
      margin-top: 10px;
      cursor: pointer;
    }
    .cardInfo {
      span {
        display: block;
        em {
          font-style: normal;
          display: inline-block;
        }
      }
    }
    .titleInfo {
      span.cartSet,
      span.cardName {
        display: block;
        font-family: "Source Sans Pro", sans-serif;
      }
      span.cartSet {
        font-weight: 600;
        margin-bottom: 5px;
      }
    }
    span.childPaused {
      text-align: center;
      width: 100%;
      height: 125px;
      color: grey;
      cursor: pointer;
    }
    .panel {
      p.participants {
        letter-spacing: 0;
        color: #4d4f5c;
        text-transform: uppercase;
        font-size: 13px;
        font-family: "Source Sans Pro", sans-serif;
        text-align: center;
        margin-bottom: 4px;
        height: 22px;
        line-height: 22px;
        a {
          color: #00bdbc;
          cursor: pointer;
          text-decoration: none;
          font-weight: 600;
          &.active,
          &:hover {
            color: #05a0a0;
            transition: all 1s;
          }
        }
        em {
          font-style: normal;
        }
      }
      span.editBtn,
      a.editBtn {
        background: #00bdbc;
        height: 60px;
        display: block;
        color: white;
        cursor: pointer;
        text-align: center;
        line-height: 60px;
        text-decoration: none;
        text-transform: uppercase;
        font-family: "Source Sans Pro", sans-serif;
        &.half {
          width: 50%;
          display: inline-block !important;
          cursor: pointer;
        }
        &.active,
        &:hover {
          background: #05a0a0;
          transition: all 1s;
        }
      }
      p.types {
        letter-spacing: 0;
        color: #4d4f5c;
        text-transform: uppercase;
        font-size: 15px;
        font-family: "Source Sans Pro", sans-serif;
        em {
          display: block;
          font-style: normal;
          text-decoration: unset;
          color: #a3a4aa;
        }
      }
      span.eventTitle {
        letter-spacing: 0;
        color: #4d4f5c;
        font-size: 26px;
        width: 100%;
        display: block;
        padding: 0 15px;
        line-height: 33px;
        height: 66px;
        overflow: hidden;
        margin-top: 5px;
        margin-bottom: 5px;
      }
      p.info {
        color: #00bdbc;
        font-family: "Source Sans Pro", sans-serif;
        text-transform: uppercase;
        a {
          color: #00bdbc;
          text-decoration: none;
          cursor: pointer;
          &.active,
          &:hover {
            color: #05a0a0 !important;
            transition: all 1s;
          }
        }
        i {
          color: #4d4f5c;
          padding-right: 8px;
        }
      }
      .eventImage {
        width: 386px;
        height: 217px;
        overflow: hidden;
        img {
          max-width: 100%;
          width: 100%;
        }
        span.ticketIcon {
          background: rgba(255, 255, 255, 0.8);
          width: 80px;
          height: 80px;
          border-radius: 50px;
          display: block;
          position: absolute;
          overflow: hidden;
          display: block;
          margin-left: 301px;
          margin-top: 132px;
          img {
            width: 90%;
            margin: 0 auto;
            display: block;
            top: 50%;
            position: relative;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

//Cart Reviews
table.cartViewTenders {
  margin-top: 24px;
  margin-bottom: 24px;
}
.cartViewTop {
  .avatar {
    width: 42px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    border-radius: 25px;
    color: white;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 22px;
    display: inline-block;
    font-weight: 300;
    text-transform: uppercase;
    background: #f18a80;
  }
}
.cartviewBottom {
  margin-bottom: 20px;
  img.cart-view-image {
    max-width: 53px;
    max-height: 79px;
    margin-left: 19px;
    margin-top: 6px;
    margin-bottom: 6px;
  }
  table tr {
    line-height: 28px;
  }
  .cartViewTotals {
    .cartViewTitle {
      color: #404144;
      em {
        font-style: normal;
        font-weight: 600;
        float: right;
        padding-right: 15px;
      }
    }
  }
}

//Google Address
.pac-container {
  background: white;
  position: relative;
  z-index: 9999999999;
  .pac-icon {
    color: #00bdbc !important;
  }
  .pac-item-selected {
    background: #00bdbc;
  }
  .pac-item {
    color: #4d4f5c;
    cursor: pointer;
    &:hover {
      background: #00bdbc;
    }
  }
}

//Customers & Users
#userList {
  a {
    margin: 0 15px;
  }
}
.buylistDetailsPanels,
.adjustStoreCreditModal,
#userList {
  p.reason {
    display: inline-block;
    padding-left: 16px;
    margin: 0;
  }
  .storeCredit {
    border-left: 2px solid #00bdbc;
    margin-bottom: 23px;
    tr {
      border-left: none;
      line-height: 16px;
    }
  }
  input#adjustPrice {
    border: 1px solid #ebebf2;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 5px;
  }
  textarea {
    border: 1px solid #ebebf2;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 5px;
  }
  select {
    background: #00bdbc 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 6px #0000000a;
    border-radius: 5px;
    border: 1px solid #00bdbc;
    color: white;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 15px;
    text-align: center;
    text-align-last: center;
    width: 100%;
    max-width: 206px;
    height: 26px;
    margin-bottom: 15px;
    display: block;
    cursor: pointer;
    background-position: right;
    padding: 2px 20px;
    line-height: 18px;
    option {
      text-align: center;
      text-align-last: center;
    }
    &.active,
    &:hover {
      background: #05a0a0;
      transition: all 1s;
      color: white;
      text-decoration: none;
    }
  }
  table {
    color: #a3a4aa;
    a {
      color: #a3a4aa;
    }
    i {
      color: #00bdbc;
      &.active,
      &:hover {
        color: #05a0a0;
        transition: all 1s;
        text-decoration: none;
      }
    }
    .text-bold {
      color: #4d4f5c;
    }
    .avatar {
      width: 42px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      border-radius: 25px;
      color: white;
      font-family: "Source Sans Pro", sans-serif;
      font-size: 22px;
      display: inline-block;
      font-weight: 300;
      text-transform: uppercase;
      &.red {
        background: #f18a80;
      }
      &.blue {
        background: #8582dc;
      }
    }
  }
  input#customerSearch {
    border: 1px solid #00bdbc;
    border-radius: 14px;
    padding: 5px 0px 5px 36px;
    font-size: 13px;
    color: #4d4f5c;
    font-family: "Source Sans Pro", sans-serif;
    width: 268px;
    &:focus {
      outline: none;
    }
  }
  a.btn1 {
    margin: 0 7px;
    color: white !important;
    i {
      padding-right: 5px;
    }
  }
}

//Buylist
.pendingBuylist,
.completedBuylist,
.approvedBuylist {
  .text-bold {
    color: #4d4f5c;
  }
  .avatar {
    width: 42px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    border-radius: 25px;
    color: white;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 22px;
    display: inline-block;
    margin-right: 19px;
    font-weight: 300;
    &.red {
      background: #f18a80;
    }
    &.blue {
      background: #8582dc;
    }
  }
  .btn1 {
    min-width: 100px;
    i.fas {
      font-size: unset;
    }
  }
}

.buylistRules {
  margin-bottom: 30px;
  .userActions {
    color: #00bdbc !important;
    font-size: 18px;
    margin: 0 9px;
    cursor: pointer;
    &.active,
    &:hover {
      color: #05a0a0 !important;
      transition: all 1s;
    }
  }
  .checkbox {
    margin-top: -16px;
    margin-left: 14px;
    padding-left: 19px;
  }
  .multipleSelect {
    cursor: pointer;
    .checkbox {
      margin-top: -12px;
    }
    &.active {
      color: #0a162e;
      transition: all 1s;
      text-decoration: none;
    }
    span.text {
      position: relative;
      left: 61px;
      i {
        padding-left: 5px;
      }
    }
  }
}

.table-controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 1em;
  .date-select {
    font-size: 0.8em;
    margin-bottom: 1em;
  }
  .paging {
    text-align: right;
    margin-bottom: 20px;
  }
}

.order_list {
  .paging {
    margin-top: 20px;
  }
}
//pagination
.paging-nav-body {
  text-align: right;
  button {
    background: #4d4f5c;
    box-shadow: 0px 2px 6px #0000000a;
    border-radius: 5px;
    border: none;
    color: white;
    font-size: 15px;
    font-family: "Source Sans Pro", sans-serif;
    margin: 0 5px;
    padding: 0 20px;
    height: 24px;
  }
}
//Queue
.queueFilterTabs {
  color: white;
  line-height: 38px;
  background: #0a162e;
  font-weight: 600;
  span {
    margin: 0 17px;
    display: inline-block;
    cursor: pointer;
    &.active {
      color: #00bdbc;
    }
    &:hover {
      color: #06d8d7;
    }
  }
}
.queueResults {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 10px #00000026;
  padding: 21px;
  .queueLine {
    box-shadow: 0px 2px 10px #00000026;
    margin-bottom: 24px;
    .progress {
      position: absolute;
      right: 109px;
      margin-top: -8px;
      width: 80%;
      max-width: 561px;
      border-radius: 11px;
      background-color: #6c6d75;
      .progress-bar {
        background: transparent
          linear-gradient(180deg, #25cecd 0%, #00b4b3 100%) 0% 0% no-repeat
          padding-box;
        font-family: "Source Sans Pro", sans-serif;
      }
    }
    .info {
      min-height: 70px;
      background: linear-gradient(
        -45deg,
        #ffffff 0%,
        #ffffff 0%,
        white 65%,
        #ebebf2 39%
      );
      .container-fluid .row {
        min-height: 70px;
        .searchFilters {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          height: max-content;
          display: block;
          color: #0a162e;
          font-family: "Source Sans Pro", sans-serif;
          em {
            font-style: normal;
            color: #a3a4aa;
          }
        }
        .updates {
          position: relative;
          text-align: right;
          top: 50%;
          transform: translateY(-50%);
          height: max-content;
          display: block;
          color: #0a162e;
          font-family: "Source Sans Pro", sans-serif;
          em {
            font-style: normal;
            color: #a3a4aa;
          }
        }
      }
    }
    .queueJobHeader {
      background: #00bdbc;
      height: 60px;
      line-height: 60px;
      color: #0a162e;
      text-transform: uppercase;
      font-family: "Source Sans Pro", sans-serif;
      em {
        font-style: normal;
        color: white;
      }
    }
  }
}

//Till Screen
.modal.till {
  input[type="text"] {
    border: 1px solid #ebebf2;
    border-radius: 5px;
    font-family: "Source Sans Pro", sans-serif;
    width: 100%;
    border: 1px solid #ebebf2;
    color: #4d4f5c;
    padding-left: 15px;
    right: 314px;
    margin-bottom: 15px;
    &:focus {
      outline: none;
    }
  }
  textarea#tillDescription {
    border: 1px solid #ebebf2;
    border-radius: 5px;
    font-family: "Source Sans Pro", sans-serif;
    width: 100%;
    height: 150px;
    border: 1px solid #ebebf2;
    color: #4d4f5c;
    padding-left: 15px;
    right: 314px;
    margin-bottom: 15px;
    &:focus {
      outline: none;
    }
  }
}
p.tillInfo {
  color: #a3a4aa;
  margin-top: -24px;
}

//Products
#inventoryManagement {
  .btn1 {
    margin-right: 4px;
    margin-left: 4px;
  }
  .resultsPanel {
    .col-xl-1,
    .col-xl-2 {
      padding-right: 3px;
      padding-left: 3px;
      // max-height: 58px;
    }
    input {
      padding: 0;
      text-align: center;
      margin: 10px 0px;
      color: #a3a4aa;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }
    .lineItem {
      box-shadow: 0px 2px 10px #00000026;
      width: 100%;
      margin-bottom: 22px;
      .btn1 {
        margin: 15px 0px;
        min-width: 100%;
        top: 1px;
      }
      .toggleVariantsButton {
        float: right;
        background-color: white;
        border: 1px solid #00bdbc;
        border-radius: 7px;
        padding: 0 1rem;
        min-width: 10rem;
        &:hover,
        &:focus,
        &:active {
          outline-color: #05a0a0;
        }
        & > i {
          color: #00bdbc;
        }
      }
      .variantsInfo {
        padding: 23px;
        .variantLine {
          border-bottom: 1px solid #d0d0d0;
          margin-bottom: 12px;
          .row-wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
          }
          .price {
            line-height: 58px;
            color: #a3a4aa;
          }
          .input-group {
            width: 7em;
          }
          .currency-input {
            > input {
              width: 4.5em;
              margin-left: 10px;
              margin-right: 10px;
            }
          }
          .percentage-input {
            > input {
              width: 2.5em;
            }
          }
          .quantity-input {
            > input {
              width: 3.5em;
              margin-left: 10px;
              margin-right: 10px;
            }
          }
          .row {
            // height: 58px;
          }
          .variantTitle {
            height: max-content;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            color: #4d4f5c;
            font-size: 15px;
            font-family: "Source Sans Pro", sans-serif;
            @media only screen and (max-width: 1199px) {
              padding-left: 1em;
              padding-bottom: 0.2em;
            }
          }
          .grouping {
            display: flex;
            flex-direction: row;
          }
          .variantInfo {
            width: 2.5em;
            .fas {
              top: 1em;
              display: inline-block;
              padding: 0.4em;
              margin-top: 0.6em;
              margin-left: 2px;
              font-size: 1.2em;
              color: #00bdbc;
              width: 1.2em;
              &:hover {
                color: #333;
                cursor: pointer;
              }
            }
          }
          .text-small {
            .currency-input {
              width: 4.5em;
              margin-left: 10px;
            }
            .percentage-input {
              width: 2.5em;
              margin-right: 10px;
            }
            input {
              width: calc(50% - 14px);
              display: inline-block;
            }
            span {
              display: inline-block;
              margin: 0 6px;
              color: #a3a4aa;
            }
          }
        }
        .cluster {
          background: #00bdbc;
          height: 24px;
          border-radius: 26px;
          margin: 17px 0px;
          .circleBtn {
            display: inline-block;
            vertical-align: top;
            background: white;
            color: #00bdbc;
            height: 20px;
            width: 20px;
            margin: 2px 2px;
            border-radius: 20px;
            line-height: 23px;
            text-align: center;
            i {
              position: relative;
              top: 3px;
              cursor: pointer;
            }
          }
          input {
            display: inline-block;
            width: 50px;
            vertical-align: top;
            color: white;
            margin: 0;
            height: 24px;
            background: none;
            border: none;
            outline: none;
            padding: 0;
            text-align: center;
            &:focus,
            &.active {
              outline: none;
            }
          }
        }
        .variant-input-header {
          background: #00bdbc;
          color: white;
          font-family: "Source Sans Pro", sans-serif;
          font-size: 13px;
          text-align: center;
          margin: 0 auto;
          width: 100%;
          @media only screen and (min-width: 1750px) {
            display: none;
          }
        }

        .variantSelectTitles {
          background: #00bdbc;
          color: white;
          font-family: "Source Sans Pro", sans-serif;
          font-size: 13px;
          line-height: 30px;
          height: 30px;
          padding-left: 15px;
          @media only screen and (min-width: 1750px) {
            margin-left: -12px;
          }
          @media only screen and (max-width: 1749px) {
            display: none;
          }
          .input-group {
            width: 8.4em;
          }
          .variantInfo {
            width: 2em;
          }
          .currency-input {
            width: 4.6em;
          }
          .quantity-input {
            width: 5.4em;
          }
          .multi-input {
            width: 12.5em;
          }
          .configurable-fields div {
            display: inline-block;
            text-align: center;
          }
          span {
            margin: auto;
          }
        }
      }
      .productHeader {
        background: #f1f1f5;
        padding: 15px 0;
        .imgWrapper {
          width: 50px;
          height: 69px;
          display: inline-block;
          img {
            max-width: 50px;
            max-height: 69px;
            margin: 0 auto;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        .productTitle {
          display: inline-block;
          width: calc(100% - 164px);
          vertical-align: top;
          padding-left: 10px;
          color: #4d4f5c;
          span {
            display: block;
          }
        }
        .productData {
          text-align: right;
          color: #0a162e;
          font-size: 28px;
        }
      }
    }
  }
  button.batchUpdate {
    background: #00bdbc;
    height: 66px;
    width: 296px;
    margin-top: 25px;
    margin-bottom: 17px;
    &.active,
    &:hover {
      background: #05a0a0;
      transition: all 1s;
      color: white;
      text-decoration: none;
    }
  }
  .sort {
    width: 100%;
    .sortWrapper {
      width: calc(100% - 64px);
      display: inline-block;
      margin-left: 8px;
      text-align: center;
      div {
        background: #00bdbc;
        color: white;
        border-radius: 5px;
        text-align: center;
      }
    }
    label.sort {
      letter-spacing: 0;
      color: #4d4f5c;
      display: inline-block;
      width: max-content;
    }
  }
  .searchCount {
    color: #00bdbc;
    text-transform: uppercase;
  }
  .tabBlock {
    display: inline-block;
  }
  .filtersWrapper3 {
    background: #f1f1f5;
    margin: 19px;
    margin-top: 0;
    .applyFilters {
      padding: 0;
      button {
        height: 66px;
        background: #00bdbc;
        width: 100%;
        border: none;
        color: white;
        text-transform: uppercase;
        &.active,
        &:hover {
          background: #05a0a0;
          transition: all 1s;
          color: white;
          text-decoration: none;
        }
      }
    }
    label.label {
      line-height: 17px;
      color: #4d4f5c;
      font-family: "Source Sans Pro", sans-serif;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
    .select2 {
      margin-left: 15px;
      border: 2px solid #00bdbc;
      border-radius: 44px;
      color: #4d4f5c;
      font-size: 13px;
      margin-top: 12px;
      .icon {
        position: absolute;
        color: #4fbdbb;
        top: 27px;
        margin-left: 15px;
      }
      .css-yk16xz-control {
        border-radius: 44px;
        padding-left: 35px;
        &:focus {
          outline: none;
        }
      }
    }
  }
  #savedFilters {
    box-shadow: 0px 2px 10px #00000026;
    padding: 24px;
    .savedFilters {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 10px #00000026;
      max-height: 536px;
      overflow: auto;
      overflow-x: hidden;
      margin-bottom: 17px;
      .savedActions {
        i.fal.fa-trash {
          color: #c37979;
          cursor: pointer;
        }
        .btn1 {
          display: inline-block;
          width: calc(100% - 35px);
          margin-left: 13px;
        }
      }
      .savedName {
        letter-spacing: 0;
        color: #4d4f5c;
        line-height: 30px;
        font-family: "Source Sans Pro", sans-serif;
        padding-left: 30px;
      }
      .savedQueries {
        color: #a3a4aa;
        line-height: 30px;
        font-family: "Source Sans Pro", sans-serif;
      }
      .filterBar {
        background: #00bdbc;
        height: 38px;
        line-height: 38px;
        color: white;
        padding: 0 15px;
        font-family: "Source Sans Pro", sans-serif;
        font-weight: 600;
        margin-bottom: 11px;
      }
    }
  }
  #modifyFilters {
    box-shadow: 0px 2px 10px #00000026;
    padding: 19px;
    margin-bottom: 17px;
    .modifyFilters {
      background: #f1f1f5;
      padding: 11px 0px;
      label.checkbox {
        letter-spacing: 0;
        color: #4d4f5c;
        font-size: 15px;
      }
      .checkbox .checkmark {
        top: -1px;
      }
    }
  }
  #filters {
    box-shadow: 0px 2px 10px #00000026;
    padding-bottom: 4px;
    margin-bottom: 17px;
    .topcontent {
      background: #00bdbc;
      padding: 18px 0px;
      padding-bottom: 23px;
      .select2 {
        color: #4d4f5c;
        font-size: 13px;
        .css-yk16xz-control {
          border-radius: 44px;
          padding-left: 17px;
          div {
            padding-left: 17px;
            border-radius: 0px;
            color: #4d4f5c;
            font-family: "Source Sans Pro", sans-serif;
          }
        }
        .icon {
          position: absolute;
          left: 29px;
          top: 42px;
          color: #4fbdbb;
        }
      }
    }
    .filtersWrapper,
    .filtersWrapper2 {
      background: #f1f1f5;
      margin: 19px;
      padding: 15px;
      margin-bottom: 0;
      .saveFilterWrapper {
        text-align: right;
        margin-top: 36px;
        input#saveFilter {
          width: 300px;
          display: inline-block;
          height: 26px;
        }
        button.btn1 {
          width: 199px;
          display: inline-block;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          position: relative;
          top: 1px;
        }
      }
      label.label {
        letter-spacing: 0;
        color: #4d4f5c;
        margin-bottom: 3px;
        display: block;
      }
      input[type="text"],
      input[type="number"] {
        border: 1px solid #ebebf2;
        height: 38px;
        width: 100%;
        color: #4d4f5c;
        padding-left: 15px;
      }
      .select2 {
        input[type="text"] {
          height: unset;
          color: #4d4f5c;
        }
        .css-yk16xz-control {
          border-radius: 0px;
          padding-left: 15px;
        }
      }
    }
    .filtersWrapper2 {
      background: white;
      border: 1px solid #ebebf2;
    }
  }
  .filterTabs {
    background: #0a162e;
    height: 38px;
    color: white;
    line-height: 38px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    span {
      cursor: pointer;
      margin: 0 20px;
      &.minimizeFilters {
        position: absolute;
        right: 70px;
        &:after {
          content: "\f06e";
          top: 1px;
        }
        &.expanded {
          &:after {
            content: "\f070";
            top: 1px;
          }
        }
      }
      &:after {
        content: "\f0de";
        font-family: "font awesome 5 pro";
        position: relative;
        top: 5px;
        left: 10px;
      }
      &.active {
        color: #00bdbc;
        &:after {
          content: "\f0dd";
          font-family: "font awesome 5 pro";
          position: relative;
          top: -2px;
          left: 10px;
        }
      }
      &:hover {
        color: #06d8d7;
      }
    }
    a {
      color: white;
      text-decoration: none;
      span:after {
        content: "";
      }
    }
  }
}
//Info Input Pages
.infoInputPages {
  button.buttonWide {
    width: 100%;
    border: none;
  }
  label.label {
    letter-spacing: 0;
    color: #4d4f5c;
    font-family: "Source Sans Pro", sans-serif;
    margin-bottom: 2px;
  }
  input {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #00000026;
    letter-spacing: 0;
    color: #4d4f5c;
    padding-left: 10px;
    font-family: "Source Sans Pro", sans-serif;
    margin-bottom: 20px;
  }
  textarea#notes {
    width: 100%;
    border: 1px solid #00000026;
    padding-left: 10px;
    height: 178px;
    &:focus {
      outline: none;
    }
  }
}

// POS
#binderpos-pos {
  margin: 0;
  padding: 0;
  top: 0;
  width: 100%;
  position: fixed;
  z-index: 9999;
  height: 100vh;
  left: 0;
  background: #f1f1f1;
  .modal {
    .closeTitle {
      color: #0a162e;
      font-size: 28px;
      font-family: "Source Sans Pro", sans-serif;
    }
    h5.title-lrg {
      letter-spacing: 0;
      color: #0a162e;
      font-weight: 300;
      font-family: "Source Sans Pro", sans-serif;
      font-size: 28px;
      margin-bottom: 41px;
    }
    span.openDate {
      color: #a3a4aa;
      text-transform: uppercase;
      font-size: 15px;
      margin-bottom: 16px;
      display: block;
      font-family: "Source Sans Pro", sans-serif;
    }
    .wrapper.floatAdj {
      input.OpeningAmount {
        color: #a3a4aa;
      }
      .checkbox {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 15px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-family: "Source Sans Pro", sans-serif;
        color: #4d4f5c;
        .checkmark {
          top: -2px;
        }
      }
      input[type="textbox"] {
        display: block;
        width: 100%;
        padding-left: 15px;
        border: 1px solid #ebebf2;
        height: 36px;
        margin-bottom: 35px;
      }
      select.tillDropdown {
        background: #00bdbc 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 6px #0000000a;
        border-radius: 5px;
        border: 1px solid #00bdbc;
        color: white;
        font-family: "Source Sans Pro", sans-serif;
        font-size: 15px;
        text-align: center;
        text-align-last: center;
        width: 100%;
        height: 35px;
        display: block;
        margin: 0 auto;
        margin-bottom: 40px;
        cursor: pointer;
        background-position: right;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        padding: 4px 20px;
      }
    }
    table {
      box-shadow: none;
      thead {
        color: white;
        font-size: 15px;
        font-family: "Source Sans Pro", sans-serif;
        height: 36px;
        th {
          text-align: center;
          &:first-child {
            padding-left: 10px;
            text-align: left;
          }
        }
      }
      tbody {
        tr {
          border-left: none;
          height: 45px;
          line-height: 45px;
          td {
            text-align: center;
            &:first-child {
              padding-left: 10px;
              font-weight: 600;
              color: #4d4f5c;
              text-align: left;
            }
            &.counted {
              width: 198px;
              input {
                height: 28px;
                padding-left: 15px;
                width: 150px;
                position: relative;
                border: 1px solid #ebebf2;
              }
            }
          }
        }
      }
    }
  }
  .app-content {
    height: 100vh;
    section.section {
      height: 100vh;
      .App {
        height: 100vh;
        .navbar {
          height: 85px;
          background: #0a162e;
          background: url(../assets/img/watermarkMenu.png), #0a162e;
          background-repeat: no-repeat;
          background-repeat: no-repeat;
          background-position-x: 297px;
          background-position: right;
          background-position-y: -26px;
          span.leavePOS {
            position: fixed;
            right: 11px;
            color: white;
            font-size: 15px;
            letter-spacing: -1px;
            top: 32px;
            cursor: pointer;
            i {
              font-size: 40px;
              position: absolute;
              left: -31px;
              top: -7px;
            }
          }
          .posMenu {
            position: absolute;
            right: 148px;
            background: #00bdbc;
            box-shadow: 0px 2px 6px #0000000a;
            border-radius: 5px;
            border-top-right-radius: 14px;
            border-bottom-right-radius: 14px;
            height: 32px;
            padding-left: 38px;
            .mobileMenu--kiosk {
              padding-left: 0.5rem;
              padding-right: 0.5rem;
              & > * {
                margin-left: 0.5rem;
                margin-right: 0.5rem;
              }
            }
            .link-icon-pos {
              display: inline-block;
              color: white;
              font-family: "Source Sans Pro", sans-serif;
              padding: 0px 15px;
              font-size: 15px;
              cursor: pointer;
              &.active,
              &:hover {
                color: rgba(10, 22, 46, 0.6);
                transition: all 1s;
                text-decoration: none;
              }
              i {
                font-size: 24px;
                position: relative;
                top: 4px;
                right: 4px;
              }
            }
            .customer-select {
              display: inline-block;
              margin-left: 33px;
              i.far.fa-search {
                color: #00bdbc;
                position: absolute;
                top: 9px;
                margin-left: 12px;
              }
              span.addCustomer {
                right: 11px;
                top: 7px;
                position: absolute;
                color: #4fbdbb;
                font-size: 19px;
              }
              input {
                border: 2px solid #00bdbc;
                border-radius: 14px;
                height: 32px;
                width: 299px;
                font-size: 13px;
                letter-spacing: 0;
                font-family: "Source Sans Pro", sans-serif;
                color: #4d4f5c;
                padding-left: 33px;
                padding-right: 39px;
                &:focus {
                  outline: none;
                }
              }
              .CustomerInfo {
                position: fixed;
                right: 20px;
                text-align: right;
                top: 94px;
                letter-spacing: 0;
                color: #4d4f5c;
                font-size: 13px;
                font-family: "Source Sans Pro", sans-serif;
                span {
                  display: block;
                  line-height: 16px;
                }
                &__row {
                  display: flex;
                  justify-content: space-between;
                }
              }
              .CustomerNote {
                line-height: 16px;
                margin-right: 14px;
                &:hover {
                  cursor: pointer;
                }
              }
              .autocomplete-items {
                display: block;
                position: absolute;
                z-index: 9999;
                background: rgba(255, 255, 255, 0.9);
                right: 0;
                box-shadow: 0px 2px 10px #000000b8;
                border: 1px solid rgba(10, 22, 46, 0.4);
                border-top: none;
                top: 58px;
                padding: 10px 20px;
                width: 300px;
                .customer {
                  height: 46px;
                  &:not(:last-child) {
                    border-bottom: 1px solid #c5c5c5;
                  }
                  &:hover {
                    margin-left: 2px;
                  }
                  .customerSearchInfo {
                    display: inline-block;
                    width: calc(100% - 82px);
                    height: 38px;
                    padding-left: 13px;
                    overflow: hidden;
                    cursor: pointer;
                    position: relative;
                    top: 50%;
                    transform: translateY(-50%);
                    .name,
                    .email {
                      letter-spacing: 0;
                      color: #4d4f5c;
                      font-size: 13px;
                      font-family: "Source Sans Pro", sans-serif;
                      width: 100%;
                      display: block;
                    }
                  }
                  .credit {
                    position: absolute;
                    right: 0;
                    vertical-align: top;
                    margin-top: 14px;
                    right: 21px;
                    font-weight: 600;
                    letter-spacing: 0;
                    color: #4d4f5c;
                    font-size: 13px;
                    font-family: "Source Sans Pro", sans-serif;
                  }
                  .initials {
                    vertical-align: top;
                    p {
                      background: #4fbdbb;
                      color: white;
                      display: inline-block;
                      width: 35px;
                      height: 35px;
                      line-height: 35px;
                      text-align: center;
                      border-radius: 35px;
                      margin: 0;
                      position: relative;
                      top: 50%;
                      transform: translateY(-50%);
                    }
                  }
                }
              }
            }
          }
          .posMenu--kiosk {
            border-top-left-radius: 14px;
            border-bottom-left-radius: 14px;
            padding-top: 1px;
          }
        }
        .body {
          .loader {
            position: fixed;
          }
          .product-search {
            background: rgba(255, 255, 255, 0.93);
            box-shadow: 0px 3px 6px #00000029;
            width: calc(100vw - 425px);
            position: absolute;
            top: 110px;
            border-top: 8px solid #0a162e;
            left: 20px;
            height: calc(100vh - 110px);
            padding-right: 99px;
            form.search-form {
              height: 65px;
              .search-bar {
                .suggestBox {
                  background: white;
                  position: fixed;
                  top: 178px;
                  box-shadow: 0px 2px 6px #0000000a;
                  border: 1px solid #ebebf2;
                  border-radius: 5px;
                  left: 93px;
                  z-index: 9999;
                  width: calc(calc(100vw - 475px) - 30%);
                  letter-spacing: 0;
                  color: #a3a4aa;
                  font-size: 15px;
                  font-family: "Source Sans Pro", sans-serif;
                  div {
                    padding-left: 25px;
                    height: 40px;
                    display: block;
                    line-height: 40px;
                    cursor: pointer;
                    &:hover {
                      color: #7c7d7f;
                    }
                    &:nth-child(even) {
                      background: #f1f1f5;
                    }
                  }
                }
                .suggestToggle {
                  position: fixed;
                  top: 150px;
                  letter-spacing: 0;
                  color: #ffffff;
                  background: #00bdbc;
                  font-size: 13px;
                  font-family: "Source Sans Pro", sans-serif;
                  height: 28px;
                  border-radius: 27px;
                  left: calc(calc(100vw - 533px) - 30%);
                  z-index: 9;
                  width: 136px;
                  line-height: 28px;
                  padding-left: 16px;
                  span {
                    vertical-align: top;
                  }
                  .switch {
                    height: 24px;
                    position: relative;
                    left: 11px;
                    width: 52px;
                    top: 2px;
                    .slider:before {
                      height: 24px;
                      width: 24px;
                    }
                  }
                }
                input[type="text"] {
                  border: 2px solid #00bdbc;
                  border-radius: 14px;
                  width: calc(calc(100vw - 533px) - 30%);
                  padding-left: 15px;
                  letter-spacing: 0;
                  font-family: "Source Sans Pro", sans-serif;
                  font-size: 13px;
                  color: #4d4f5c;
                  height: 28px;
                  position: fixed;
                  top: 150px;
                  left: 92px;
                  &:focus {
                    outline: none;
                  }
                }
              }
              .switcher {
                position: fixed;
                top: 150px;
                right: 581px;
                .switch {
                  width: 91px;
                  input:checked + .slider {
                    background-color: #e2b561;
                    &:before {
                      left: 38px;
                    }
                    &:after {
                      content: "Buy";
                      position: absolute;
                      left: 23px;
                      line-height: 29px;
                      color: white;
                      text-transform: capitalize;
                    }
                  }
                  .slider {
                    background-color: #00bdbc;
                    &:before {
                      position: absolute;
                      content: "";
                      height: 24px;
                      width: 24px;
                      left: 2px;
                      bottom: 2px;
                      background-color: white;
                      -webkit-transition: 0.4s;
                      transition: 0.4s;
                    }
                    &:after {
                      content: "sell";
                      position: absolute;
                      left: 39px;
                      line-height: 29px;
                      color: white;
                      text-transform: capitalize;
                    }
                  }
                }
              }
            }
            .results-grid {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 138px;
              max-height: calc(100vh - 256px);
              width: calc(100vw - 523px);
              overflow: auto;
              .cardpos {
                width: 250px;
                height: 405px;
                background: #ffffff 0% 0% no-repeat padding-box;
                box-shadow: 0px 2px 10px #00000026;
                margin: 15px 10px;
                &.stockLowStock {
                  span.stock {
                    background: #4d4f5c 0% 0% no-repeat padding-box;
                  }
                }
                &.stockOutofStock {
                  span.stock {
                    background: #b78292 0% 0% no-repeat padding-box;
                  }
                }
                &.stockAvailable {
                  span.stock {
                    //background: #7aa761 0% 0% no-repeat padding-box;
                    background: #4d4f5c 0% 0% no-repeat padding-box;
                  }
                }
                .cartBadge {
                  height: 32px;
                  position: relative;
                  margin-left: 230px;
                  margin-top: -15px;
                  margin-bottom: -16px;
                }
                span.stock {
                  border-radius: 14px;
                  height: 21px;
                  color: #ffffff;
                  font-family: "Source Sans Pro", sans-serif;
                  padding: 0 15px;
                  margin: 4px auto;
                  display: block;
                  width: max-content;
                  line-height: 21px;
                }
                .cardpos-container {
                  width: 184px;
                  height: 229px;
                  margin: 0 auto;
                  img {
                    max-width: 184px;
                    max-height: 229px;
                    margin: 0 auto;
                    display: block;
                  }
                }
                .price {
                  background: #0a162e;
                  width: 50%;
                  display: inline-block;
                  height: 40px;
                  letter-spacing: 0;
                  color: #ffffff;
                  font-size: 15px;
                  font-family: "Source Sans Pro", sans-serif;
                  line-height: 40px;
                  text-align: center;
                  margin-top: 5px;
                }
                .addToCart {
                  background: #00bdbc;
                  width: 50%;
                  display: inline-block;
                  height: 40px;
                  letter-spacing: 0;
                  color: #ffffff;
                  font-size: 15px;
                  font-family: "Source Sans Pro", sans-serif;
                  line-height: 40px;
                  text-align: center;
                  margin-top: 5px;
                  cursor: pointer;
                  &:hover {
                    background: #05a0a0;
                    transition: all 1s;
                    color: white;
                    text-decoration: none;
                  }
                }
                .no-stock {
                  background: #0a162e;
                  width: 50%;
                  display: inline-block;
                  height: 40px;
                  letter-spacing: 0;
                  color: #ffffff;
                  font-size: 15px;
                  font-family: "Source Sans Pro", sans-serif;
                  line-height: 40px;
                  text-align: center;
                  margin-top: 5px;
                }
                .productDetails {
                  span.title {
                    letter-spacing: 0;
                    color: #a3a4aa;
                    font-size: 15px;
                    text-align: center;
                    height: 62px;
                    display: block;
                    margin: 7px 0;
                    overflow: hidden;
                    padding: 0 12px;
                  }
                  span.sellPrice {
                    font-size: 0.8rem;
                    text-align: center;
                    margin: auto;
                    display: block;
                    margin-top: -0.4rem;
                    color: #0a162e99;
                    transition: ease-in-out height 0.5s;
                    &--hidden {
                      height: 0;
                    }
                    &--visible {
                      height: 1.2rem;
                    }
                  }
                  span.variant {
                    width: calc(100% - 12px);
                    display: block;
                    margin: 0 auto;
                    position: relative;
                    select {
                      background: #00bdbc 0% 0% no-repeat padding-box;
                      box-shadow: 0px 2px 6px #0000000a;
                      border-radius: 5px;
                      border: 1px solid #00bdbc;
                      color: white;
                      font-family: "Source Sans Pro", sans-serif;
                      font-size: 15px;
                      text-align: center;
                      text-align-last: center;
                      width: 100%;
                      height: 26px;
                      display: block;
                      margin: 0 auto;
                      cursor: pointer;
                      background-position: right;
                      padding: 2px 20px;
                      option {
                        text-align: center;
                        text-align-last: center;
                      }
                      &.active,
                      &:hover {
                        background: #05a0a0;
                        transition: all 1s;
                        color: white;
                        text-decoration: none;
                      }
                    }
                  }
                }
              }
              .upNav {
                height: 30px;
                border-radius: 4px;
                color: #0a162e;
                font-size: 28px;
                padding-left: 42px;
                margin-bottom: 14px;
                cursor: context-menu;
                position: fixed;
                top: 199px;
                left: 39px;
              }

              .quickLinks {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                .quickItem {
                  user-select: none;
                  text-align: center;
                  background-color: white;
                  box-shadow: 0px 3px 6px #00000029;
                  cursor: pointer;
                  &.blank {
                    box-shadow: none;
                    background-color: rgba(0, 0, 0, 0);
                  }
                  &.disabled {
                    -webkit-filter: blur(4px);
                    -moz-filter: blur(4px);
                    -ms-filter: blur(4px);
                    -o-filter: blur(4px);
                    filter: blur(4px);
                    pointer-events: none;
                  }
                  &.addItem {
                    box-shadow: none;
                    background-color: rgba(0, 0, 0, 0);
                    border-style: dashed;
                    border-color: rgba(0, 0, 0, 0.061);
                    box-shadow: 0px 3px 6px #00000029;
                    color: rgba(0, 0, 0, 0.061);
                    border-bottom-width: 2px;
                    &:hover {
                      .dot {
                        background-color: rgba(0, 0, 0, 0.1);
                      }
                    }
                    .dot {
                      color: rgba(0, 0, 0, 0.061);
                      background-color: rgba(0, 0, 0, 0.061);
                    }
                    h1 {
                      color: rgba(160, 62, 62, 0.161) !important;
                    }
                  }
                  img {
                    background-color: white;
                    height: 45%;
                    max-width: 45%;
                    margin: 7% 0px 0px 0%;
                    pointer-events: none;
                  }
                  .dot {
                    border-radius: 50%;
                    display: inline-block;
                    text-align: center;
                    width: 31%;
                    height: 31%;
                    color: white;
                    text-align: center;
                    display: block;
                    margin: 18% auto 8.5% auto;
                    i {
                      font-size: 200%;
                      position: relative;
                      top: 50%;
                      transform: translatey(-50%);
                    }
                  }

                  .itemDesc {
                    margin: 4.2% 0px 0px 0%;
                    height: 30%;
                    align-items: center;
                    text-align: center;
                    font-family: Poppins;
                    font-style: normal;
                    hr {
                      margin-top: 0px;
                      margin-bottom: 2px;
                      border: 0;
                      border-top: 2px solid rgba(0, 0, 0, 0.1);
                      width: 77%;
                    }
                    h1 {
                      margin-top: 0;
                      margin-bottom: 4px;
                      letter-spacing: 0;
                      color: #0a162e;
                      font-size: 22px;
                      font-family: "Source Sans Pro", sans-serif;
                      height: 50px;
                      margin: 0;
                      line-height: 21px;
                    }
                    p.variantName {
                      font-size: 15px;
                      margin-top: 0px;
                      font-weight: 500;
                      color: gray;
                      margin: 0;
                    }
                    p.setName {
                      font-size: 12px;
                      margin-top: 0px;
                      margin: 0;
                      font-weight: 500;
                      color: gray;
                    }
                  }
                  display: inline-block;
                  margin: 8px;
                  width: 250px;
                  height: 250px;
                  box-shadow: 0px 3px 6px #00000029;
                  border-bottom: 20px solid;
                }
              }
            }
          }
          .cart {
            position: fixed;
            right: 20px;
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0px 2px 10px #00000052;
            height: calc(100vh - 414px);
            top: 162px;
            z-index: 99;
            width: 483px;
            overflow-y: auto;
            padding-top: 14px;
            padding: 20px 22px;
            &.disable {
              overflow-x: hidden;
              overflow-y: hidden;
            }
            .cartItem {
              min-height: 80px;
              margin-bottom: 13px;
              border-bottom: 4px solid;
              padding-bottom: 12px;
              span.remove {
                position: absolute;
                right: 22px;
                margin-top: -13px;
                color: fff;
                font-size: 11px;
                cursor: pointer;
                background: #fddddd;
                padding: 0 5px;
                &:hover {
                  background: #e85151;
                  transition: all 0.3s;
                }
              }
              .kiosk-remove-wrapper {
                position: relative;
                width: 8rem;
                display: inline-block;
                top: 1rem;
              }
              .cartBadge {
                position: absolute;
                left: 58px;
                margin-top: -7px;
              }
              &.buy {
                border-color: #00bdbc;
              }
              &.sell {
                border-color: #e2b561;
                .qtySelector {
                  background: #e2b561 !important;
                  border-color: #e2b561 !important;
                  span.minus,
                  span.plus {
                    color: #e2b561 !important;
                  }
                }
              }
              &.disabled {
                .remove {
                  background: grey;
                }
                .qtySelector {
                  background: grey !important;
                  border: 2px solid grey !important;
                }
                .minus {
                  color: grey !important;
                }
                .plus {
                  color: grey !important;
                }
                cursor: wait !important;
                pointer-events: none;
              }
              &.disabled * {
                cursor: wait !important;
                pointer-events: none;
              }
              img.img {
                max-width: 50px;
                max-height: 69px;
                display: inline-block;
                vertical-align: top;
              }
              .itemWrapper {
                display: inline-block;
                width: calc(100% - 50px);
                padding-left: 15px;
                letter-spacing: 0;
                color: #a3a4aa;
                font-family: "Source Sans Pro", sans-serif;
                font-size: 15px;
                .discounts {
                  position: relative;
                  top: 10px;
                  left: -65px;
                  .discountWrapper {
                    background: #00bdbc;
                    border: 2px solid #00bdbc;
                    border-radius: 14px;
                    height: 26px;
                    width: 108px;
                    display: inline-block;
                    vertical-align: top;
                    position: relative;
                    top: -3px;
                    left: 9px;
                    button {
                      background: white;
                      height: 22px;
                      width: 22px;
                      border-radius: 19px;
                      display: inline-block;
                      color: #00bdbc;
                      line-height: 14px;
                      font-weight: 900;
                      vertical-align: top;
                      outline: none;
                      border: none;
                      padding: 0;
                      cursor: pointer;
                      &.notActive {
                        color: #babdbd;
                      }
                    }
                    input {
                      display: inline-block;
                      font-family: "Source Sans Pro", sans-serif;
                      width: 60px;
                      background: none;
                      border: none;
                      color: white;
                      text-align: center;
                    }
                  }
                }
                .data {
                  form {
                    margin-top: 8px;
                    input {
                      width: 286px;
                      padding-left: 15px;
                      border: none;
                      outline: none;
                      color: #a3a4aa;
                      &:focus {
                        font-family: "Source Sans Pro", sans-serif;
                      }
                      &::-webkit-input-placeholder {
                        color: #b9b9bb;
                        font-family: "Source Sans Pro", sans-serif;
                      }
                      &:-ms-input-placeholder {
                        color: #b9b9bb;
                        font-family: "Source Sans Pro", sans-serif;
                      }
                      &:placeholder {
                        color: #b9b9bb;
                        font-family: "Source Sans Pro", sans-serif;
                      }
                    }
                  }
                }
                button.customAdd {
                  background: none;
                  border: none;
                  color: #00bdbc;
                  font-size: 32px;
                  float: right;
                  top: 14px;
                  &.active,
                  &:hover {
                    color: #0a162e;
                    transition: all 1s;
                    text-decoration: none;
                  }
                }
                .actions {
                  height: 22px;
                  display: block;
                  span.lineDiscount {
                    position: absolute;
                    right: 149px;
                    width: calc(100% - 321px);
                    display: block;
                    color: grey;
                    cursor: pointer;
                    margin-top: -20px;
                    font-size: 12px;
                    margin-bottom: 0;
                    &:hover {
                      color: #5f5a5a;
                    }
                  }
                  .greybox {
                    width: 124px;
                    background: #f1f1f5;
                    display: inline-block;
                    position: absolute;
                    right: 20px;
                    color: #4d4f5c;
                    font-size: 13px;
                    font-family: "Source Sans Pro", sans-serif;
                    height: 23px;
                    line-height: 23px;
                    padding-left: 12px;
                    &:hover {
                      background: #ecfdfd;
                    }
                    span.remove {
                      position: absolute;
                      right: 22px;
                      margin-top: -13px;
                      color: fff;
                      font-size: 11px;
                      cursor: pointer;
                      background: #fddddd;
                      padding: 0 5px;
                      &.active,
                      &:hover {
                        color: #0a162e;
                        transition: all 1s;
                        text-decoration: none;
                      }
                    }
                    span.tax {
                      position: absolute;
                      right: 4px;
                      text-align: right;
                      cursor: pointer;
                      &.active,
                      &:hover {
                        color: #0a162e;
                        transition: all 1s;
                        text-decoration: none;
                      }
                    }
                  }
                  .qtySelector {
                    background: #00bdbc;
                    border: 2px solid #00bdbc;
                    border-radius: 14px;
                    height: 23px;
                    width: 74px;
                    display: inline-block;
                    vertical-align: top;
                    span.minus,
                    span.plus {
                      background: white;
                      height: 19px;
                      width: 19px;
                      border-radius: 19px;
                      display: inline-block;
                      color: #00bdbc;
                      text-align: center;
                      line-height: 19px;
                      font-weight: 900;
                      vertical-align: top;
                      cursor: pointer;
                    }
                    input[type="number"] {
                      position: relative;
                      border: none;
                      background: none;
                      vertical-align: top;
                      height: 19px;
                      display: inline-block;
                      width: 32px;
                      text-align: center;
                      padding: 0;
                      font-family: "Source Sans Pro", sans-serif;
                      color: white;
                      &::-webkit-inner-spin-button,
                      &::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                      }
                      &:focus {
                        outline: none;
                      }
                    }
                  }
                }
                .data {
                  .titles {
                    display: inline-block;
                    width: 258px;
                    vertical-align: top;
                    min-height: 44px;
                    overflow: hidden;
                    position: relative;
                    top: -4px;
                  }
                  .inputs {
                    display: inline-block;
                    width: 111px;
                    font-size: 12px;
                    margin-top: 4px;
                    input[type="number"] {
                      width: 100%;
                      text-align: right;
                      border: none;
                      color: #4d4f5c;
                      font-family: "Source Sans Pro", sans-serif;
                      &::-webkit-inner-spin-button,
                      &::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                      }
                      &:focus {
                        outline: none;
                      }
                    }
                  }
                }
                small {
                  position: relative;
                  top: -2px;
                }
              }
            }
            .cartTotals {
              background: #f1f1f5;
              bottom: 95px;
              position: fixed;
              height: 157px;
              width: 483px;
              box-shadow: 0px 5px 10px #00000066;
              padding: 19px;
              right: 20px;
              em {
                position: absolute;
                right: 20px;
                text-align: right;
                font-weight: 600;
                font-style: normal;
              }
              .discounts {
                letter-spacing: 0;
                color: #4d4f5c;
                text-transform: uppercase;
                font-family: "Source Sans Pro", sans-serif;
                font-size: 15px;
                .discountWrapper {
                  background: #00bdbc;
                  border: 2px solid #00bdbc;
                  border-radius: 14px;
                  height: 26px;
                  width: 108px;
                  display: inline-block;
                  vertical-align: top;
                  position: relative;
                  top: -3px;
                  left: 9px;
                  input {
                    display: inline-block;
                    font-family: "Source Sans Pro", sans-serif;
                    width: 60px;
                    background: none;
                    border: none;
                    color: white;
                    text-align: center;
                    &:focus {
                      outline: none;
                    }
                  }
                  button {
                    background: white;
                    height: 22px;
                    width: 22px;
                    border-radius: 19px;
                    display: inline-block;
                    color: #00bdbc;
                    line-height: 14px;
                    font-weight: 900;
                    vertical-align: top;
                    outline: none;
                    border: none;
                    padding: 0;
                    cursor: pointer;
                    &.notActive {
                      color: #babdbd;
                    }
                  }
                }
              }
              .subTotal {
                display: block;
                letter-spacing: 0;
                color: #4d4f5c;
                text-transform: uppercase;
                font-family: "Source Sans Pro", sans-serif;
                font-size: 15px;
              }
              .tax {
                display: block;
                letter-spacing: 0;
                color: #4d4f5c;
                text-transform: uppercase;
                font-family: "Source Sans Pro", sans-serif;
                font-size: 15px;
                border-bottom: 1px solid #b0b1b5;
                padding-bottom: 9px;
                margin-bottom: 9px;
                span.taxNegated {
                  text-transform: initial;
                  font-size: 12px;
                  position: relative;
                  left: 10px;
                  top: -1px;
                  color: #a3a4aa;
                }
              }
              .total {
                display: block;
                font-size: 30px;
                font-family: "Source Sans Pro", sans-serif;
                color: #0a162e;
              }
            }
          }
          button.buy-button {
            background: #00bdbc;
            right: 20px;
            color: white;
            border: none;
            position: fixed;
            bottom: 36px;
            height: 60px;
            font-family: "Source Sans Pro", sans-serif;
            font-size: 15px;
            text-transform: uppercase;
            width: 483px;
            box-shadow: 0px 5px 10px #00000066;
            &.active,
            &:hover {
              background: #05a0a0 !important;
              transition: all 1s;
            }
            i {
              font-size: 24px;
              position: relative;
              top: 4px;
              padding-left: 2px;
            }
          }
          .cartNumber {
            position: fixed;
            right: 20px;
            text-align: right;
            top: 132px;
            letter-spacing: 0;
            color: #4d4f5c;
            font-size: 13px;
            font-family: "Source Sans Pro", sans-serif;
            .fa-print {
              margin-right: 10px;
            }
          }
          .customItem {
            position: fixed;
            right: 277px;
            text-align: left;
            top: 132px;
            color: #4d4f5c;
            font-size: 13px;
            font-family: "Source Sans Pro", sans-serif;
            cursor: pointer;
          }
          .cartNote {
            position: fixed;
            right: 171px;
            text-align: left;
            top: 132px;
            color: #4d4f5c;
            font-size: 13px;
            font-family: "Source Sans Pro", sans-serif;
            cursor: pointer;
            border: none;
            padding: 0;
            background: none;
          }
        }
      }
    }
  }
}

.EditCustomerNote {
  width: 100%;
  height: 100%;
  resize: none;
}

//POS Add Customer
.POScustomer {
  h2.title.is-2 {
    display: none;
  }
}

//tender Wrapper
.tenderWrapper {
  .tenderAmounts {
    .tenderBtn {
      input {
        width: 50%;
        display: inline-block;
        letter-spacing: 0;
        color: #a3a4aa;
        text-align: center;
        border: 1px solid #d3d5e0;
        height: 36px;
        vertical-align: middle;
        border-right: none;
        position: relative;
        top: -1px;
        line-height: 36px;
        font-size: 15px;
        font-family: "Source Sans Pro", sans-serif;
      }
      .payment-button {
        display: inline-block;
        width: 50%;
        background: #00bdbc;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border: none;
        color: white;
        font-size: 15px;
        font-family: "Source Sans Pro", sans-serif;
        height: 36px;
        margin-bottom: 10px;
        margin-top: 10px;
        &.active,
        &:hover {
          background: #05a0a0;
          transition: all 1s;
          color: white;
          text-decoration: none;
        }
      }
    }
  }
  span.balance {
    letter-spacing: 0;
    color: #0a162e;
    font-size: 28px;
    font-family: "Source Sans Pro", sans-serif;
    display: block;
    margin-top: 40px;
    em {
      font-style: normal;
      font-weight: 600;
      position: absolute;
      right: 86px;
    }
  }
  span.tenderTitle {
    color: #0a162e;
    font-size: 28px;
    font-family: "Source Sans Pro", sans-serif;
    display: block;
    margin-top: 7px;
    span.tender {
      position: absolute;
      width: 150px;
      right: 81px;
      input {
        width: 148px;
        font-family: "Source Sans Pro", sans-serif;
        font-weight: 600;
        font-size: 28px;
        text-align: right;
        color: #0a162e;
        border: 1px solid #bfbfbf;
        cursor: text;
        padding-right: 3px;
        border-bottom: 2px double;
        border-radius: 3px;
        &:focus {
          outline: none;
        }
      }
    }
  }
  .quickBtns {
    span.changeDue {
      text-align: right;
      float: right;
      position: relative;
      top: 5px;
    }
    button.payment-button-cash {
      width: 20%;
      background: #f1f1f5;
      border: none;
      letter-spacing: 0;
      color: #4d4f5c;
      font-family: "Source Sans Pro", sans-serif;
      height: 36px;
      line-height: 36px;
      margin-top: 7px;
      &.active,
      &:hover {
        background: #e2e2e2;
        transition: all 1s;
        text-decoration: none;
      }
      &:nth-child(even) {
        background: #00bdbc;
        color: white;
        &.active,
        &:hover {
          background: #05a0a0;
          transition: all 1s;
          color: white;
          text-decoration: none;
        }
      }
    }
  }
}
//Settings Pages
a.viewCart {
  color: #00bdbc;
  &:hover {
    color: #05a0a0;
    transition: all 1s;
    text-decoration: none;
  }
}
h3.pricingSectionHeader {
  font-weight: 600;
  margin-top: 26px;
  margin-bottom: 20px;
}
h4.priceFor {
  font-size: 20px !important;
  margin-bottom: 17px;
  color: #4d4e5c;
}

h5.priceFloors {
  font-size: 15px !important;
  margin-bottom: 17px;
  color: #4d4e5c;
  text-align: center;
  margin-top: 26px;
  margin-bottom: 20px;
}
p.subTitlePricing {
  font-size: 14px;
  color: grey;
  margin-top: -22px;
}
hr.big {
  margin-bottom: 46px;
  margin-top: 41px;
}
.report-setting {
  h3 {
    color: #00bdbc;
    font-size: 1.2em !important;
    text-decoration: underline;
  }
  .row {
    margin-left: 1em;
    margin-bottom: 1em;
    .checkmark {
      top: unset;
    }
  }
  .btn {
    background: #00bdbc;
    color: white;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 15px;
    width: 100%;
    display: block;
    text-align: center;
    border: none;
    height: 36px;
    line-height: 26px;
  }
}

// Login Page
#login-page {
  top: 0 !important;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .leftBg {
    background-size: cover;
    background-image: linear-gradient(
        to right,
        rgba(8, 15, 30, 0.9),
        rgba(8, 15, 30, 0.9)
      ),
      url(../assets/img/loginbg.jpg);
    background-blend-mode: hard-light;
    height: 100vh;
    overflow: hidden;
    .login-logo {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 403px;
    }
  }
  .loginWrapper {
    max-width: 533px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "Source Sans Pro", sans-serif;
    .failed {
      color: #eb5757;
      padding: 0 23px;
      text-align: center;
      i {
        padding-right: 10px;
      }
    }
    .reset {
      color: #25bb77;
      padding: 0 23px;
      text-align: center;
      i {
        padding-right: 10px;
      }
    }
    .recover {
      padding-bottom: 25px;
    }
    .title {
      margin-bottom: 0;
      font-family: "Source Sans Pro", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 45px;
      text-align: center;
      color: #0a162e;
      text-align: center;
      em {
        color: #00bdbc;
        font-style: normal;
      }
    }
    .wrongAccount {
      padding: 0px 23px;
      text-align: center;
      font-family: "Source Sans Pro", sans-serif;
      margin-bottom: 24px;
    }
    .errorDescription {
      margin-left: 32px;
      margin-right: 28px;
    }
    .subTitle {
      font-family: "Source Sans Pro", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #0a162e;
    }
    label {
      background: #ffffff;
      border: 1px solid #c7c7c7;
      box-sizing: border-box;
      border-radius: 5px;
      max-width: 475px;
      width: 100%;
      height: 70px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      line-height: 65px;
      font-family: "Source Sans Pro", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      color: #0a162e;
      padding-left: 37px;
      margin-bottom: 17px;
    }
    input {
      position: absolute;
      right: 0;
      outline: none;
      border: none;
      width: calc(100% - 137px);
      height: 67px;
      &::placeholder {
        font-family: "Source Sans Pro", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 31px;
        display: flex;
        align-items: center;
        color: #c7c7c7;
      }
      &:placeholder-shown {
        height: 25px;
        margin-top: 17px;
      }
      &:-webkit-input-placeholder {
        color: transparent;
      }
      &:focus::-moz-placeholder {
        color: transparent;
      }
      &:-moz-placeholder {
        color: transparent;
      }
    }
    .link {
      text-align: center;
      padding-top: 9px;
      a {
        font-family: "Source Sans Pro", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 31px;
        color: #00bdbc !important;
        cursor: pointer;
      }
    }
    .signin {
      background: #00bdbc;
      border-radius: 5px;
      max-width: 475px;
      width: 100%;
      height: 70px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      font-family: "Source Sans Pro", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      display: block;
      align-items: center;
      text-align: center;
      color: #ffffff;
      border: none;
    }
    .firebaseui-container {
      max-width: 475px;
      .firebaseui-card-content,
      .firebaseui-card-footer {
        padding: 0;
      }
      .firebaseui-id-idp-button {
        background: #0a162e;
        border-radius: 5px;
        color: white;
        max-width: 475px;
        height: 70px;
        width: 100%;
        .firebaseui-idp-text {
          font-family: "Source Sans Pro", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          text-align: center;
          color: #ffffff !important;
        }
        .firebaseui-idp-text-long {
          display: inline-block;
          position: relative;
          left: 40%;
          transform: translateX(-36%);
        }
        .firebaseui-idp-icon-wrapper {
          position: relative;
          left: 25%;
          display: inline-block;
          transform: translateX(-50%);
        }
      }
    }
  }
}

// Mobile POS
.App.mobile {
  display: none;
  .modal {
    top: -95px;
    height: calc(100% + 95px);
    .modal-card {
      width: 90vw;
    }
  }
  .ham-menu {
    position: fixed;
    right: 23px;
    color: white;
    font-size: 37px;
    z-index: 1;
    top: 27px;
  }
  .react-swipeable-view-container {
    width: 100vw;
    position: fixed;
    // height: calc(100vh - 96px);
    height: calc(100% - 96px);
    .bodyPanel {
      width: 100vw;
      .wrapper {
        margin: 22px;
        background: rgba(255, 255, 255, 0.8);
        padding: 20px;
        border-top: 8px solid #0a162e;
        .results-grid {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          max-height: calc(100vh - 220px);
          overflow: auto;
          .cardpos {
            width: 250px;
            height: 405px;
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0px 2px 10px #00000026;
            margin: 15px 10px;
            pointer-events: none;
            &.stockLowStock {
              span.stock {
                background: #4d4f5c 0% 0% no-repeat padding-box;
              }
            }
            &.stockOutofStock {
              span.stock {
                background: #b78292 0% 0% no-repeat padding-box;
              }
            }
            &.stockAvailable {
              span.stock {
                //background: #7aa761 0% 0% no-repeat padding-box;
                background: #4d4f5c 0% 0% no-repeat padding-box;
              }
            }
            .cartBadge {
              height: 32px;
              position: relative;
              margin-left: 230px;
              margin-top: -15px;
              margin-bottom: -16px;
            }
            span.stock {
              border-radius: 14px;
              height: 21px;
              color: #ffffff;
              font-family: "Source Sans Pro", sans-serif;
              padding: 0 15px;
              margin: 4px auto;
              display: block;
              width: max-content;
              line-height: 21px;
            }
            .cardpos-container {
              width: 184px;
              height: 229px;
              margin: 0 auto;
              img {
                max-width: 184px;
                max-height: 229px;
                margin: 0 auto;
                display: block;
              }
            }
            .price {
              background: #0a162e;
              width: 50%;
              display: inline-block;
              height: 40px;
              letter-spacing: 0;
              color: #ffffff;
              font-size: 15px;
              font-family: "Source Sans Pro", sans-serif;
              line-height: 40px;
              text-align: center;
              margin-top: 5px;
            }
            .addToCart {
              background: #00bdbc;
              width: 50%;
              display: inline-block;
              height: 40px;
              letter-spacing: 0;
              color: #ffffff;
              font-size: 15px;
              font-family: "Source Sans Pro", sans-serif;
              line-height: 40px;
              text-align: center;
              margin-top: 5px;
              cursor: pointer;
              pointer-events: all;
              &:hover {
                background: #05a0a0;
                transition: all 1s;
                color: white;
                text-decoration: none;
              }
            }
            .no-stock {
              background: #0a162e;
              width: 50%;
              display: inline-block;
              height: 40px;
              letter-spacing: 0;
              color: #ffffff;
              font-size: 15px;
              font-family: "Source Sans Pro", sans-serif;
              line-height: 40px;
              text-align: center;
              margin-top: 5px;
            }
            .productDetails {
              span.title {
                letter-spacing: 0;
                color: #a3a4aa;
                font-size: 15px;
                text-align: center;
                height: 62px;
                display: block;
                margin: 7px 0;
                overflow: hidden;
                padding: 0 12px;
              }
              span.sellPrice {
                font-size: 0.8rem;
                text-align: center;
                margin: auto;
                display: block;
                margin-top: -0.4rem;
                color: #0a162e99;
                transition: ease-in-out height 0.5s;
                &--hidden {
                  height: 0;
                }
                &--visible {
                  height: 1.2rem;
                }
              }
              span.variant {
                width: calc(100% - 12px);
                display: block;
                margin: 0 auto;
                position: relative;
                select {
                  background: #00bdbc 0% 0% no-repeat padding-box;
                  box-shadow: 0px 2px 6px #0000000a;
                  border-radius: 5px;
                  border: 1px solid #00bdbc;
                  color: white;
                  font-family: "Source Sans Pro", sans-serif;
                  font-size: 15px;
                  text-align: center;
                  text-align-last: center;
                  width: 100%;
                  height: 26px;
                  display: block;
                  margin: 0 auto;
                  pointer-events: all;
                  cursor: pointer;
                  background-position: right;
                  padding: 2px 20px;
                  option {
                    text-align: center;
                    text-align-last: center;
                  }
                  &.active,
                  &:hover {
                    background: #05a0a0;
                    transition: all 1s;
                    color: white;
                    text-decoration: none;
                  }
                }
              }
            }
          }
          .quickLinks {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            width: 100%;
            .quickItem {
              user-select: none;
              text-align: center;
              background-color: white;
              box-shadow: 0px 3px 6px #00000029;
              cursor: pointer;
              &.blank {
                box-shadow: none;
                background-color: rgba(0, 0, 0, 0);
              }
              &.addItem {
                display: none;
              }
              img {
                background-color: white;
                height: 45%;
                max-width: 45%;
                margin: 7% 0px 0px 0%;
                pointer-events: none;
              }
              .dot {
                border-radius: 50%;
                display: inline-block;
                text-align: center;
                width: 31%;
                height: 31%;
                color: white;
                text-align: center;
                display: block;
                margin: 18% auto 8.5% auto;
                i {
                  font-size: 200%;
                  position: relative;
                  top: 50%;
                  transform: translatey(-50%);
                }
              }

              .itemDesc {
                margin: 4.2% 0px 0px 0%;
                height: 30%;
                align-items: center;
                text-align: center;
                font-family: Poppins;
                font-style: normal;
                hr {
                  margin-top: 0px;
                  margin-bottom: 2px;
                  border: 0;
                  border-top: 2px solid rgba(0, 0, 0, 0.1);
                  width: 77%;
                }
                h1 {
                  margin-top: 0;
                  margin-bottom: 4px;
                  letter-spacing: 0;
                  color: #0a162e;
                  font-size: 22px;
                  font-family: "Source Sans Pro", sans-serif;
                  height: 50px;
                  margin: 0;
                  line-height: 21px;
                }
                p.variantName {
                  font-size: 15px;
                  margin-top: 0px;
                  font-weight: 500;
                  color: gray;
                  margin: 0;
                }
                p.setName {
                  font-size: 12px;
                  margin-top: 0px;
                  margin: 0;
                  font-weight: 500;
                  color: gray;
                }
              }
              display: inline-block;
              margin: 8px;
              width: 250px;
              height: 250px;
              box-shadow: 0px 3px 6px #00000029;
              border-bottom: 20px solid;
            }
          }
        }
        .search-bar {
          .suggestBox {
            background: white;
            position: fixed;
            top: 85px;
            box-shadow: 0px 2px 6px #0000000a;
            border: 1px solid #ebebf2;
            border-radius: 5px;
            left: 93px;
            z-index: 9999;
            width: calc(100vw - 380px);
            letter-spacing: 0;
            color: #a3a4aa;
            font-size: 15px;
            font-family: "Source Sans Pro", sans-serif;
            div {
              padding-left: 25px;
              height: 40px;
              display: block;
              line-height: 40px;
              cursor: pointer;
              &:hover {
                color: #7c7d7f;
              }
              &:nth-child(even) {
                background: #f1f1f5;
              }
            }
          }
          .suggestToggle {
            position: absolute;
            top: 50px;
            letter-spacing: 0;
            color: #ffffff;
            background: #00bdbc;
            font-size: 13px;
            font-family: "Source Sans Pro", sans-serif;
            height: 28px;
            border-radius: 27px;
            left: calc(100vw - 404px);
            z-index: 9;
            width: 136px;
            line-height: 28px;
            padding-left: 16px;
            span {
              vertical-align: top;
            }
            .switch {
              height: 24px;
              position: relative;
              left: 11px;
              width: 52px;
              top: 2px;
              .slider:before {
                height: 24px;
                width: 24px;
              }
            }
          }
          input[type="text"] {
            border: 2px solid #00bdbc;
            border-radius: 14px;
            width: calc(100vw - 357px);
            padding-left: 23px;
            letter-spacing: 0;
            font-family: "Source Sans Pro", sans-serif;
            font-size: 13px;
            color: #4d4f5c;
            height: 28px;
            margin-left: 45px;
            &:focus {
              outline: none;
            }
          }
        }
        .switcher {
          position: absolute;
          top: 50px;
          right: 125px;
          .switch {
            width: 91px;
            input:checked + .slider {
              background-color: #e2b561;
              &:before {
                left: 38px;
              }
              &:after {
                content: "Buy";
                position: absolute;
                left: 23px;
                line-height: 29px;
                color: white;
                text-transform: capitalize;
              }
            }
            .slider {
              background-color: #00bdbc;
              &:before {
                position: absolute;
                content: "";
                height: 24px;
                width: 24px;
                left: 2px;
                bottom: 2px;
                background-color: white;
                -webkit-transition: 0.4s;
                transition: 0.4s;
              }
              &:after {
                content: "sell";
                position: absolute;
                left: 39px;
                line-height: 29px;
                color: white;
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }
    .cartPanel {
      height: calc(100% - 45px);
      display: flex;
      flex-direction: column;

      // -
      margin: 22px;
      background: rgba(255, 255, 255, 0.8);
      padding: 20px;
      border-top: 8px solid #0a162e;

      .modal.display-block {
        left: 100vw;
      }

      // --------

      .cartNote {
        border: none;
        padding: 0;
        background: none;
      }
      .cart {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      }
      .cart-header {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        .cartNumber {
          flex-basis: 50%;
        }
        .customItem {
          order: 2;
        }
      }
      .cart-items {
        flex: 1 1 auto;
        overflow-y: auto;
        height: 0px;
        padding-top: 13px;
      }
      .cartTotals {
        width: 100%;
        padding-top: 5px;
        background: rgba(255, 255, 255, 0.8);
        margin: 0 auto;
        em {
          text-align: right;
          font-weight: 600;
          font-style: normal;
          float: right;
        }
        .discounts {
          letter-spacing: 0;
          color: #4d4f5c;
          text-transform: uppercase;
          font-family: "Source Sans Pro", sans-serif;
          font-size: 15px;
          .discountWrapper {
            background: #00bdbc;
            border: 2px solid #00bdbc;
            border-radius: 14px;
            height: 26px;
            width: 108px;
            display: inline-block;
            vertical-align: top;
            position: relative;
            top: -3px;
            left: 9px;
            input {
              display: inline-block;
              font-family: "Source Sans Pro", sans-serif;
              width: 60px;
              background: none;
              border: none;
              color: white;
              text-align: center;
              &:focus {
                outline: none;
              }
            }
            button {
              background: white;
              height: 22px;
              width: 22px;
              border-radius: 19px;
              display: inline-block;
              color: #00bdbc;
              line-height: 14px;
              font-weight: 900;
              vertical-align: top;
              outline: none;
              border: none;
              padding: 0;
              cursor: pointer;
              &.notActive {
                color: #babdbd;
              }
            }
          }
        }
        .subTotal {
          display: block;
          letter-spacing: 0;
          color: #4d4f5c;
          text-transform: uppercase;
          font-family: "Source Sans Pro", sans-serif;
          font-size: 15px;
        }
        .tax {
          display: block;
          letter-spacing: 0;
          color: #4d4f5c;
          text-transform: uppercase;
          font-family: "Source Sans Pro", sans-serif;
          font-size: 15px;
          border-bottom: 1px solid #b0b1b5;
          padding-bottom: 9px;
          margin-bottom: 9px;
          span.taxNegated {
            text-transform: initial;
            font-size: 12px;
            position: relative;
            left: 10px;
            top: -1px;
            color: #a3a4aa;
          }
        }
        .total {
          display: block;
          font-size: 30px;
          font-family: "Source Sans Pro", sans-serif;
          color: #0a162e;
        }
      }

      .cart {
        overflow-y: hidden;
        // overflow: auto;
        height: calc(100vh - 383px);
        margin-top: 9px;
        padding-top: 14px;
        button.buy-button {
          width: 100%;
          background: #4fbdbb;
          border: none;
          color: white;
          height: 60px;
          display: block;
        }
        .cartItem {
          min-height: 80px;
          margin-bottom: 13px;
          border-bottom: 3px solid;
          padding-bottom: 12px;

          &:last-of-type {
            border-bottom: none;
            margin-bottom: 5px;
            padding-bottom: 0;
          }
          span.remove {
            position: absolute;
            right: 0;
            margin-top: -15px;
            color: fff;
            font-size: 11px;
            cursor: pointer;
            background: #fddddd;
            padding: 0 5px;
            &:hover {
              background: #e85151;
              transition: all 0.3s;
            }
          }
          .kiosk-remove-wrapper {
            position: relative;
            width: 8rem;
            display: inline-block;
            top: 1rem;
          }
          button.customAdd {
            top: 19px;
            padding-right: 17px;
          }
          .cartBadge {
            position: relative;
            margin-top: -7px;
            margin-left: -10px;
            vertical-align: top;
          }
          &.buy {
            border-color: #00bdbc;
          }
          &.sell {
            border-color: #e2b561;
            .qtySelector {
              background: #e2b561 !important;
              border-color: #e2b561 !important;
              span.minus,
              span.plus {
                color: #e2b561 !important;
              }
            }
          }
          img.img {
            max-width: 50px;
            max-height: 69px;
            display: inline-block;
            vertical-align: top;
          }
          .itemWrapper {
            display: inline-block;
            width: calc(100% - 64px);
            padding-left: 15px;
            letter-spacing: 0;
            color: #a3a4aa;
            font-family: "Source Sans Pro", sans-serif;
            font-size: 15px;
            position: relative;
            top: 2px;
            .discounts {
              position: relative;
              top: 10px;
              left: -65px;
              .discountWrapper {
                background: #00bdbc;
                border: 2px solid #00bdbc;
                border-radius: 14px;
                height: 26px;
                width: 108px;
                display: inline-block;
                vertical-align: top;
                position: relative;
                top: -3px;
                left: 9px;
                input {
                  display: inline-block;
                  font-family: "Source Sans Pro", sans-serif;
                  width: 60px;
                  background: none;
                  border: none;
                  color: white;
                  text-align: center;
                  &:focus {
                    outline: none;
                  }
                }
                button {
                  background: white;
                  height: 22px;
                  width: 22px;
                  border-radius: 19px;
                  display: inline-block;
                  color: #00bdbc;
                  line-height: 14px;
                  font-weight: 900;
                  vertical-align: top;
                  outline: none;
                  border: none;
                  padding: 0;
                  cursor: pointer;
                  &.notActive {
                    color: #babdbd;
                  }
                }
              }
            }
            .data {
              form {
                margin-top: 8px;
                input {
                  width: 286px;
                  padding-left: 6px;
                  border: none;
                  outline: none;
                  color: #a3a4aa;
                  &:focus {
                    font-family: "Source Sans Pro", sans-serif;
                  }
                  &::-webkit-input-placeholder {
                    color: #b9b9bb;
                    font-family: "Source Sans Pro", sans-serif;
                  }
                  &:-ms-input-placeholder {
                    color: #b9b9bb;
                    font-family: "Source Sans Pro", sans-serif;
                  }
                  &:placeholder {
                    color: #b9b9bb;
                    font-family: "Source Sans Pro", sans-serif;
                  }
                }
              }
            }
            button.customAdd {
              background: none;
              border: none;
              color: #00bdbc;
              font-size: 32px;
              float: right;
              top: 14px;
              &.active,
              &:hover {
                color: #0a162e;
                transition: all 1s;
                text-decoration: none;
              }
            }
            .actions {
              height: 22px;
              display: block;
              position: relative;
              top: 5px;
              span.lineDiscount {
                position: absolute;
                right: 149px;
                width: calc(100% - 252px);
                display: block;
                color: grey;
                cursor: pointer;
                margin-top: -20px;
                font-size: 12px;
                margin-bottom: 0;
                &:hover {
                  color: #5f5a5a;
                }
              }
              .greybox {
                width: 124px;
                background: #f1f1f5;
                display: inline-block;
                position: absolute;
                right: -2px;
                color: #4d4f5c;
                font-size: 13px;
                font-family: "Source Sans Pro", sans-serif;
                height: 23px;
                line-height: 23px;
                padding-left: 12px;
                &:hover {
                  background: #ecfdfd;
                }
                span.tax {
                  position: absolute;
                  right: 4px;
                  text-align: right;
                  cursor: pointer;
                  &.active,
                  &:hover {
                    color: #0a162e;
                    transition: all 1s;
                    text-decoration: none;
                  }
                }
              }
              .qtySelector {
                background: #00bdbc;
                border: 2px solid #00bdbc;
                border-radius: 14px;
                height: 23px;
                width: 74px;
                display: inline-block;
                vertical-align: top;
                span.minus,
                span.plus {
                  background: white;
                  height: 19px;
                  width: 19px;
                  border-radius: 19px;
                  display: inline-block;
                  color: #00bdbc;
                  text-align: center;
                  line-height: 19px;
                  font-weight: 900;
                  vertical-align: top;
                  cursor: pointer;
                }
                input[type="number"] {
                  position: relative;
                  border: none;
                  background: none;
                  vertical-align: top;
                  height: 19px;
                  display: inline-block;
                  width: 32px;
                  text-align: center;
                  padding: 0;
                  font-family: "Source Sans Pro", sans-serif;
                  color: white;
                  &::-webkit-inner-spin-button,
                  &::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                  }
                  &:focus {
                    outline: none;
                  }
                }
              }
            }
            .data {
              .titles {
                display: inline-block;
                width: calc(100% - 128px);
                vertical-align: top;
                min-height: 44px;
                overflow: hidden;
              }
              .inputs {
                display: inline-block;
                width: 111px;
                position: absolute;
                right: 0;
                font-size: 12px;
                margin-top: 6px;
                input[type="number"] {
                  width: 100%;
                  text-align: right;
                  border: none;
                  color: #4d4f5c;
                  font-family: "Source Sans Pro", sans-serif;
                  &::-webkit-inner-spin-button,
                  &::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                  }
                  &:focus {
                    outline: none;
                  }
                }
              }
            }
            small {
              position: relative;
              top: -2px;
            }
          }
        }
      }

      // --------
      .wrapper {
        margin: 22px;
        background: rgba(255, 255, 255, 0.8);
        padding: 20px;
        border-top: 8px solid #0a162e;
      }
    }
  }

  .CustomerInfo {
    display: flex;
    z-index: 10;
  }
}

.posMenu {
  .mobileMenu {
    display: inline-block;
  }
}
@media screen and (max-width: 1630px) {
  #inventoryManagement .resultsPanel .lineItem .variantsInfo .cluster input {
    // width: calc(100% - 48px);
  }
}
@media screen and (max-width: 1366px) {
  table {
    thead tr {
      line-height: 22px;
      font-size: 15px;
      border: none;
    }
    td {
      line-height: 24px;
    }
  }
  #inventoryManagement {
    #filters {
      .filtersWrapper label.label,
      .filtersWrapper2 label.label {
        margin-bottom: 13px;
        height: 39px;
      }
    }
    .filtersWrapper3 label.label {
      line-height: 29px;
    }
  }
}
@media screen and (max-width: 1365px) {
  .app-sidebar {
    display: none;
  }
  .topCnrBg {
    height: 120px;
    z-index: 999999;
  }
  .brand {
    width: 119px !important;
    z-index: 999999;
  }
  .company {
    display: none;
  }
  main#binderpos-app {
    left: 0 !important;
    width: 100vw !important;
    &:before,
    &:after {
      display: none;
    }
    .app-content {
      margin-top: 20px;
    }
    .tiledPanels .panelWrapper {
      max-width: 331px;
      .panel {
        p.types em {
          display: block;
          font-style: normal;
          text-decoration: unset;
          color: #a3a4aa;
          font-size: 12px;
        }
        p.info {
          color: #00bdbc;
          font-family: "Source Sans Pro", sans-serif;
          text-transform: uppercase;
          font-size: 12px;
        }
        .eventImage {
          width: 331px;
          height: 187px;
          span.ticketIcon {
            margin-left: 243px;
            margin-top: 100px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  main#binderpos-app .app-content section.section {
    padding: 10px 0px;
    .topcontent {
      label {
        margin-top: 12px;
      }
      .select2 .icon {
        top: 55px !important;
      }
    }
    .filtersWrapper {
      label {
        margin-bottom: 0 !important;
      }
      input#barcode,
      input#title {
        margin-bottom: 21px;
      }
    }
    .filtersWrapper2 label {
      margin-bottom: 0 !important;
      height: 23px !important;
      margin-top: 14px;
    }
  }
  #inventoryManagement
    #filters
    .filtersWrapper
    .saveFilterWrapper
    input#saveFilter,
  #inventoryManagement
    #filters
    .filtersWrapper2
    .saveFilterWrapper
    input#saveFilter {
    width: calc(100% - 207px);
  }
  .filtersWrapper3 .select2 {
    margin-bottom: 16px;
  }
  .applyFilters button {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  h2 {
    padding-left: 20px;
    padding-right: 20px;
  }
  #inventoryManagement .resultsPanel .lineItem .variantsInfo {
    padding: 23px 5px;
  }
  .paging-nav-body {
    padding: 13px 0px;
  }

  #inventoryManagement .resultsPanel {
    input {
      margin: 5px 0px;
    }
  }
  .mb-3,
  .my-3 {
    margin-bottom: 0 !important;
  }
  .variantline .row {
    // height: 45px !important;
  }
  p.hidden.buyLabel {
    display: block !important;
    margin: 0;
    text-align: center;
    font-size: 12px;
    margin-top: 7px;
  }
  #inventoryManagement .resultsPanel .lineItem .btn1 {
    margin: 0;
    min-width: 100%;
    top: 14px;
  }
}
@media screen and (max-width: 900px) {
  #login-page {
    display: block;
    overflow-y: scroll;
    .row {
      margin-right: 0;
      margin-left: 0;
    }
    .leftBg {
      position: absolute;
    }
    .col-md-7 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
      display: block;
      .login-logo {
        top: unset;
        transform: translateX(-50%);
        max-width: 242px;
        margin: 30px 0px;
      }
    }
    .col-md-5 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
      display: block;
      padding: 0 50px;
      .loginWrapper {
        top: 167px;
        transform: translateX(-50%);
        margin-top: 35px;
        position: absolute;
        width: calc(100vw - 49px);
        background: rgba(255, 255, 255, 0.3);
        padding: 20px;
        border-radius: 10px;
        .link {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .modal .modal-card {
    width: 90vw;
    padding: 16px;
    .modal-card-head button.delete {
      top: 28px;
      right: 18px;
    }
  }
}

@media screen and (max-width: 415px) {
  #login-page .loginWrapper .firebaseui-container .firebaseui-id-idp-button {
    .firebaseui-idp-icon-wrapper {
      left: 10%;
    }
    .firebaseui-idp-text-long {
      display: inline-block;
      position: relative;
      left: 35%;
      transform: translateX(-36%);
    }
  }
  .receiptSize {
    table {
      margin: 0 !important;
      width: 100% !important;
      td.itemTitle {
        line-height: 17px !important;
      }
      td.itemTitle {
        width: 63%;
      }
      td.price {
        line-height: 21px !important;
        em.lineTotal {
          padding-top: 10px !important;
        }
      }
    }
    .qty,
    .price {
      color: black !important;
    }
  }
}
.notActivePanel {
  pointer-events: none;
  label {
    color: #a3a4aa !important;
  }
  select {
    background: #80cac9 0% 0% no-repeat padding-box !important;
    border: 1px solid #80cac9 !important;
  }
}

//POS Media Queries
@media screen and (min-width: 1700px) and (max-width: 1856px) {
  #binderpos-pos .app-content section.section .App .navbar .posMenu {
    padding-left: 0;
    .customer-select {
      margin-left: 0;
      input {
        width: 223px;
      }
    }
  }
}
@media screen and (max-width: 1700px) {
  #binderpos-pos .app-content section.section .App .navbar .posMenu {
    border-radius: 14px;
    padding-left: 0;
    right: 20px;
    .customer-select {
      margin-left: 0px;
    }
  }
  .app-sidebar .topCnrBgLight {
    top: -30px;
  }
  .topCnrBg {
    left: -119px;
    pointer-events: none;
    position: fixed;
  }
  .brand {
    padding-top: 10px;
    pointer-events: none;
    width: 163px;
  }
  .company {
    left: -13px;
    pointer-events: none;
  }
}

@media screen and (min-width: 1365px) and (max-width: 1635px) {
  #binderpos-pos
    .app-content
    section.section
    .App
    .navbar
    .posMenu
    .customer-select
    input {
    width: 190px;
  }
}

@media screen and (min-width: 1365px) and (max-width: 1528px) {
  #binderpos-pos .app-content section.section .App .navbar .posMenu {
    .customer-select input {
      width: 190px;
    }
    .link-icon-pos {
      padding: 0px 15px;
    }
  }
}

@media screen and (max-width: 1365px) {
  .customer_list,
  .pendingBuylist,
  .completedBuylist,
  .approvedBuylist {
    width: 100%;
  }
  .App.mobile .react-swipeable-view-container .cartPanel .wrapper {
    .cart {
      height: calc(100vh - 431px);
    }
  }
  #binderpos-pos
    .app-content
    section.section
    .App
    .navbar
    .posMenu
    .link-icon-pos:hover {
    color: #fff;
  }
  .modal {
    .modal-card {
      width: 97vw !important;
      padding: 10px !important;
      &:after {
        left: 163px;
      }
      .modal-card-head button.delete {
        top: 19px;
      }
      .modal-card-body {
        .refresh {
          top: 113px;
          right: 87px;
          height: 40px;
          line-height: 41px;
        }
      }
    }
  }
  .posMenu {
    .mobileMenu {
      text-align: right;
      position: fixed;
      top: 84px;
      display: none;
      right: 0;
      width: 100vw;
      height: calc(100vh - 84px);
      z-index: 9999999;
      background: #0a162e;
      max-width: 281px;
      .link-icon-pos {
        width: 100%;
        text-align: right;
        line-height: 48px;
      }
    }
  }
  #binderpos-pos .app-content section.section .App .navbar {
    .posMenu {
      right: 75px;
    }
  }
  .App {
    &.mobile {
      display: block !important;
      .CustomerInfo {
        span {
          display: inline-block !important;
          &:first-child {
            margin-right: 20px;
          }
        }
      }
    }
    &.desktop {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .till-list
    .tiledPanels
    .panelWrapper
    .panel
    .panel-body
    .eventInfo
    .types
    em {
    margin-left: -133px;
  }
  table td {
    padding-left: 27px;
    padding-right: 28px;
  }
  .EventListing .eventInformation #weeklySelection .weekSwatchWrapper,
  .EventListing #recurringSection #weeklySelection .weekSwatchWrapper,
  .EventListing #eventImages #weeklySelection .weekSwatchWrapper,
  .EventListing #ticketInfo #weeklySelection .weekSwatchWrapper,
  .EventListing #additionalInfo #weeklySelection .weekSwatchWrapper,
  .filters .eventInformation #weeklySelection .weekSwatchWrapper,
  .filters #recurringSection #weeklySelection .weekSwatchWrapper,
  .filters #eventImages #weeklySelection .weekSwatchWrapper,
  .filters #ticketInfo #weeklySelection .weekSwatchWrapper,
  .filters #additionalInfo #weeklySelection .weekSwatchWrapper,
  .newEvent .eventInformation #weeklySelection .weekSwatchWrapper,
  .newEvent #recurringSection #weeklySelection .weekSwatchWrapper,
  .newEvent #eventImages #weeklySelection .weekSwatchWrapper,
  .newEvent #ticketInfo #weeklySelection .weekSwatchWrapper,
  .newEvent #additionalInfo #weeklySelection .weekSwatchWrapper {
    justify-content: unset;
    .weekSwatches {
      padding: unset;
    }
  }
  .EventListing {
    margin-bottom: 78px;
  }
  main#binderpos-app {
    &:before,
    &:after {
      top: 7px;
    }
    .app-content {
      margin-top: 35px;
    }
  }
  .EventListing .EventBtn,
  .filters .EventBtn,
  .newEvent .EventBtn {
    margin-right: 12px;
    margin-top: unset;
    max-width: unset;
    right: unset;
    margin: 0 auto;
    position: unset;
  }
  .control.editor {
    height: 113px;
    margin-bottom: 96px;
  }
  .EventListing #eventImages .uploadSection,
  .filters #eventImages .uploadSection,
  .newEvent #eventImages .uploadSection {
    margin-bottom: 23px;
  }
  .EventListing #ticketInfo .card-content,
  .EventListing #additionalInfo .card-content,
  .filters #ticketInfo .card-content,
  .filters #additionalInfo .card-content,
  .newEvent #ticketInfo .card-content,
  .newEvent #additionalInfo .card-content {
    height: 349px;
    margin-bottom: 28px;
    &.addNew {
      height: 128px;
    }
  }

  .EventListing #ticketInfo div#additionalInfoSection,
  .EventListing #additionalInfo div#additionalInfoSection,
  .filters #ticketInfo div#additionalInfoSection,
  .filters #additionalInfo div#additionalInfoSection,
  .newEvent #ticketInfo div#additionalInfoSection,
  .newEvent #additionalInfo div#additionalInfoSection {
    margin-bottom: 0;
  }

  .control {
    margin-bottom: 29px;
  }
}

.bulkupdate-input {
  padding-bottom: 20px;
}

@media screen and (max-width: 650px) {
  h2 {
    padding-left: 13px;
  }

  main#binderpos-app .app-content section.section .search {
    left: 0;
    margin-right: 10px;
    margin-left: 10px;
    input {
      border: 1px solid #cff3f2 !important;
      border-radius: 0px !important;
      width: 100%;
      height: 35px;
    }
  }
  main#binderpos-app .tiledPanels .panelWrapper .panel {
    width: 100%;
    .eventImage {
      width: 100%;
      span.ticketIcon {
        margin-left: 0;
        margin-top: 86px;
        right: 39px;
      }
    }
  }
  main#binderpos-app .app-content {
    margin-right: 10px;
    margin-left: 10px;
    section.section {
      padding: 11px 10px;
    }
  }
  .outer-menu .menu > div > div {
    max-height: 110vh;
    ul {
      max-height: 103vh;
    }
  }
  nav#main-nav .pull-right {
    .avatar {
      display: none;
    }
    .user {
      right: 62px;
    }
  }
  .modal .modal-card .modal-card-body .refresh {
    top: 111px;
    right: 30px;
    height: 32px;
    line-height: 32px;
  }
  .tenderWrapper {
    .tenderBtn {
      margin-right: 3px;
    }
    span.balance {
      font-size: 22px;
      em {
        right: 48px;
      }
    }
    span.tenderTitle {
      font-size: 22px;
      span.tender {
        width: 98px;
        font-size: 22px;
        right: 46px;
        input {
          width: 98px;
          font-size: 22px;
        }
      }
    }
  }

  #binderpos-pos .app-content section.section .App .navbar span.leavePOS {
    right: -18px;
    top: 220px;
    &.adj {
      top: 265px;
    }
  }
  #binderpos-pos .app-content section.section .App .navbar {
    .posMenu {
      position: fixed !important;
      top: 113px;
      margin: 0 auto;
      right: unset;
      left: 50%;
      transform: translateX(-50%);
      z-index: 9;
      .customer-select {
        .CustomerInfo {
          top: -18px;
        }
        .autocomplete-items {
          top: 32px;
        }
      }
      .mobileMenu {
        top: -30px;
        right: -14vw;
      }
      .mobileMenu .link-icon-pos {
        padding-right: 37px !important;
      }
    }
  }
  .App.mobile .react-swipeable-view-container {
    .bodyPanel {
      margin-top: 56px;
      .wrapper {
        form.search-form {
          margin: 0;
        }
      }
    }
    .cartPanel {
      margin-top: 56px;
      height: calc(100% - 79px);
      .wrapper {
        .itemWrapper .data form input {
          width: calc(100% - 107px) !important;
          background: transparent;
        }
        .customItem {
          top: 83px;
        }
        .cartNote {
          top: 83px;
        }
      }
    }
  }
  .App.mobile .react-swipeable-view-container .bodyPanel .wrapper {
    .search-bar {
      .suggestBox {
        background: white;
        position: fixed;
        top: 100px;
        box-shadow: 0px 2px 6px #0000000a;
        border: 1px solid #ebebf2;
        border-radius: 5px;
        left: 0px;
        z-index: 9999;
        overflow: hidden;
        width: 100%;
        letter-spacing: 0;
        color: #a3a4aa;
        font-size: 15px;
        font-family: "Source Sans Pro", sans-serif;
        div {
          padding-left: 25px;
          height: 40px;
          display: block;
          line-height: 40px;
          cursor: pointer;
          &:hover {
            color: #7c7d7f;
          }
          &:nth-child(even) {
            background: #f1f1f5;
          }
        }
      }
      input[type="text"] {
        width: calc(100vw - 173px);
        top: -15px;
        margin-left: 0px;
        position: relative;
      }
      .suggestToggle {
        top: 69px;
        left: calc(100vw - 187px);
        width: 55px;
        .switch {
          left: -14px;
        }
        .predictive {
          display: none;
        }
      }
    }
    .switcher {
      position: absolute;
      top: 69px;
      right: 35px;
    }
  }

  .CustomerInfo {
    display: none !important;
  }
}

@media screen and (max-width: 530px) {
  .App.mobile .react-swipeable-view-container .cartPanel .wrapper {
    .cartNote {
      right: calc(-100vw + 215px);
    }
  }
}

@media screen and (max-width: 490px) {
  .App.mobile .react-swipeable-view-container .cartPanel .wrapper {
    .cartNote {
      display: none;
    }
  }
}

@media screen and (max-width: 415px) {
  .App.mobile .react-swipeable-view-container .cartPanel {
    margin-left: 1px;
    margin-right: 1px;
    padding: 5px;
    .cart {
      margin-bottom: 9px;
      height: calc(100vh - 400px);
    }
    .customItem {
      top: 66px;
      right: calc(-100vw + 18px);
    }
    .cartTotals {
      .tax {
        padding-bottom: 4px;
        margin-bottom: 3px;
      }
      .total {
        display: block;
        font-size: 30px;
        font-family: "Source Sans Pro", sans-serif;
        color: #0a162e;
        line-height: 32px;
      }
    }
  }
}

@media screen and (max-width: 768px) and (max-height: 1024px) {
  main#binderpos-app .app-content section.section .search {
    position: absolute;
    top: 0px;
    right: 1px;
  }
}

//Custom Boostrap
@media (min-width: 1200px) {
  .col-xl-5col {
    -ms-flex: 0 0 20% !important;
    flex: 0 0 20% !important;
    max-width: 20% !important;
  }
}

// Printing
.buylistPrint {
  &__details {
    display: none;
  }
  &__cardImage {
    width: 40px;
    padding-left: 10px;

    @media print and (max-width: 8cm) {
      display: none;
    }
  }
  table {
    width: calc(100vw - 80px);
  }

  thead {
    background: #fff;
    height: 58px;
    line-height: 58px;
  }
  tr {
    line-height: 15px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 12px;
    font-weight: 600;
    height: 65px;
    color: black !important;
    border-left: none;
  }

  @media print and (max-width: 8cm) {
    &__details {
      display: block;
    }
    table {
      width: 140vw;
    }
    thead,
    tr {
      height: unset;
      font-size: 14px;
    }

    &__card {
      display: inline;
      font-weight: 600;
      &--secondary::before {
        content: " [";
      }
      &--secondary::after {
        content: "]";
      }
    }
    &__header {
      padding-right: 1em;
    }

    &__header--sell,
    &__header--secondary,
    &__sellPrice {
      display: none;
    }
  }
}

//Misc
.btn1 {
  width: max-content;
  padding: 0 22px;
  min-width: 160px;
  float: right;
  position: relative;
  top: 4px;
  margin-right: 4px;
  margin-left: 4px;
}
.rfip {
  margin: 0px;
  margin-left: 13px;
  .rfipbtn--bluegrey {
    border: 1px solid #ebebf2;
  }
}
input.rfipsearch__input {
  border-radius: unset;
  width: 100%;
  border: 0 none;
  border-bottom: 1px solid #78909c;
  padding: unset;
  position: unset;
  right: unset;
}
.rfipbtn--bluegrey .rfipbtn__button {
  border: 0 none transparent;
  border-left: 1px solid #4fbdbb;
  background-color: #4fbdbb;
  color: #ffffff;
}
.rfipdropdown--bluegrey {
  border: 1px solid #ebebf2;
}

@media print and (max-width: 1200px) {
  .modal .modal-card .modal-card-body .wrapper {
    overflow: unset;
    max-height: unset;
    padding: unset;
    overflow-x: unset;
  }
  .receipt {
    padding: 16px;
    max-width: 150vw;
    color: #000 !important;
    //margin: 0 auto;
    span.name {
      letter-spacing: 0;
      font-size: 28px;
      display: block;
      text-align: center;
      font-family: "verdana";
      color: #000 !important;
      font-weight: 600;
    }
    span.giftReceipt {
      letter-spacing: 0;
      font-size: 20px;
      display: block;
      text-align: center;
      font-family: "verdana";
      color: #000 !important;
      font-weight: 600;
    }
    span.details,
    span.taxNumber {
      font-family: "verdana";
      font-size: 18px;
      display: block;
      text-align: center;
      color: #000;
      font-weight: 600;
    }
    span.taxNumber {
      font-weight: 600;
    }
    span.saleId,
    span.dateTime,
    span.subTotal,
    span.GST,
    span.total {
      display: block;
      font-family: "verdana";
      font-size: 18px;
      font-weight: 600;
      margin-top: 5px;
      color: #000;
      &.smaller {
        font-size: 14px;
      }
      em {
        font-style: normal;
        float: right;
      }
    }
    span.total {
      font-weight: 600;
      color: #000;
    }
    span.itemTotalCount {
      text-align: center;
      color: #000;
      display: block;
      font-family: "verdana";
      font-size: 14px;
      font-weight: 600;
    }
    hr {
      margin-top: 12px;
      margin-bottom: 2px;
    }
    table {
      color: #000;
      box-shadow: none;
      tr {
        border-left: none;
        color: #000;
        line-height: 14px;
        font-size: 14px;
        td.qty {
          width: 36px;
          text-align: center;
          vertical-align: top;
          font-weight: 600;
          font-family: "verdana";
          line-height: 51px;
          color: #000;
        }
        td.itemTitle {
          padding-left: 8px;
          font-family: verdana;
          height: 54px;
          overflow: hidden;
          font-weight: 600;
          color: 000;
        }
        td.price {
          text-align: right;
          line-height: 26px;
          font-family: verdana;
          color: 000;
          em {
            display: block;
            font-style: normal;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.modal-error {
  ul {
    list-style-type: none;
    padding-inline-start: 0;
  }
}

@-moz-document url-prefix() {
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.paging-wrapper {
  margin-top: -1rem;
  margin-bottom: 0;
}

// Fix SunEditor disabled button state being overwritten
.se-btn {
  &:disabled {
    background: inherit !important;
    filter: initial;
  }
}
.sun-editor .se-container {
  height: unset;
}

// Stop toasts getting hidden by stupid z-index modals
.Toastify__toast-container {
  z-index: 9999999 !important;
}

@import "../../node_modules/suneditor/dist/css/suneditor.min.css";
@import "./reports.scss";
