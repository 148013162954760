@import "../../../../styles/global";

.ebay-order-details {
  &__section {
    display: flex;
    margin-top: gap-size();
  }
  &__section-header {
    font-size: $font-size-mid;
    font-weight: $font-weight-semi-bold;
  }

  &__group {
    display: flex;
    margin-right: gap-size();
  }

  &__label {
    color: $color-primary-dark;
    margin-right: gap-size(0.5);
  }
}
