@import "../../../styles/global";

.CustomerAvatar {
  width: 42px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  border-radius: 25px;
  color: $color-text-light;
  font-family: $font-family-secondary;
  font-size: 22px;
  display: inline-block;
  font-weight: $font-weight-light;
  text-transform: uppercase;
  &.red {
    background: #f18a80;
  }
  &.blue {
    background: #8582dc;
  }
}
