@import "../../../styles/global";

.ebay-shipping-service-settings {
  &__container {
    padding: gap-size(0.5) !important;
  }

  &__content {
    overflow-y: scroll;
  }

  &__inputs {
    display: grid;
    grid-template-columns: max-content auto;
    grid-row-gap: gap-size(0.5);
    grid-column-gap: gap-size(0.5);
  }

  &__country-select {
    > label {
      display: block;
    }
    input {
      margin-right: gap-size();
    }
  }

  &__field-error {
    color: $color-error;
    grid-column: 2;
    margin-top: -0.5rem;
    font-size: 0.9rem;
  }

  &__actions {
    margin-top: gap-size();
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-column-gap: gap-size();
    line-height: 2.5;
  }

  &__required {
    color: $color-primary-dark;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }
}
