@import "../../../styles/global";

.Thanksgiving {
  background: $color-background;
  font-family: $font-family-secondary;
  box-shadow: $shadow-standard;
  padding: gap-size();
  margin-top: gap-size(1/2);

  & > * {
    margin-bottom: 0;
  }

  &__greeting {
    margin-bottom: gap-size(1/2);
  }

  &__binderpos {
    margin-top: gap-size(1/2);
    color: $color-primary-dark;
  }

  &__link {
    color: $color-primary-dark;
    text-decoration: underline;

    &:hover {
      color: $color-primary;
    }
  }
}
