@import "../../../styles/global";
@import "../../../styles/table";
@import "../../../styles/button";

.CustomerList {
  @include table;

  &__search {
    border: $border-width-fine solid $color-primary;
    border-radius: $border-radius-large;
    margin-bottom: 1rem;
    padding: 5px 0px 5px 36px;
    font-size: 13px;
    color: $color-text-dark;
    font-family: $font-family-secondary;
    width: 268px;
    &:focus {
      outline: none;
    }
  }

  &__searchIcon {
    color: $color-primary;
    position: relative;
    left: 28px;
    top: 2px;
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: right;

    & > * {
      margin-left: gap-size();
      margin-bottom: gap-size();
    }
  }

  &__link {
    @include button;
  }
}
