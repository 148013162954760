@import "../../../styles/global";
@import "../../../styles/form";

.CatalogUpdates {
  &__descriptionText {
    font-size: $font-size-small;
    background-color: $color-grey-light;
    border: $border-width-fine solid $color-teal-dark;
    border-radius: $border-radius-small;
    padding: gap-size(0.5) gap-size() 0 gap-size();
    margin-bottom: gap-size();
  }

  &__selectProduct {
    @include select-styling;
    max-width: unset;
  }

  &__attributeTableHeader {
    display: flex;
    background-color: $color-primary-dark;
    color: $color-text-light;
    font-weight: $font-weight-semi-bold;
    margin-bottom: gap-size(0.25);
    padding: gap-size(0.25);
  }

  &__attributeTableRow {
    display: flex;
    flex-wrap: wrap;
    padding: gap-size(0.25);
  }

  &__attributeLabel {
    flex-basis: 14rem;
  }

  &__attributeCheckbox {
    flex-basis: 5rem;
    align-self: center;
  }

  &__attributeOtherGames {
    color: $color-primary-dark;
    font-size: $font-size-small;
    align-self: center;
  }

  &__saveButton {
    margin-top: gap-size();
  }
}
