@import "../../../styles/global";
@import "../../../styles/form";

.ebay-listing-form {
  @include default-form;

  &__setting {
    display: flex;
    flex-wrap: wrap;
    margin-top: gap-size(2);
  }

  &__label {
    flex-basis: 15rem;
  }

  &__editor {
    flex-grow: 1;
    flex-basis: 35rem;
    margin-right: gap-size(2);
  }

  &__inputs {
    margin-top: gap-size(2);
    margin-right: gap-size(2);
    grid-template-columns: minmax(14.5rem, max-content) auto;
  }

  &__select-input {
    @include select-styling-subtle;
    max-width: unset;
    text-align: left;
    margin-bottom: unset;
  }

  &__compound-input {
    display: flex;
    align-items: center;
    & > * {
      flex: 1;
    }
    & > input {
      padding-left: gap-size() !important;
      text-align: unset !important;
      color: $color-text-dark !important;
    }
    & > select {
      height: 1.7rem;
      margin-left: 1rem;
    }
  }
}
