.UserAvatar {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__image {
    margin-left: 0.5rem;
    width: 42px;
    height: 42px;
    border-radius: 39px;
    overflow: hidden;
    border: 2px solid #00bdbc;

    img {
      width: 100%;
    }
  }

  &__greeting {
    color: #4fbdbb;
    font-size: 13px;

    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  @media screen and (max-width: 420px) {
    display: none;
  }
}
