// Submitted Carts
.cart-list {
  .data-table {
    .BaseTable {
      font-size: 0.75em;
    }
  }
  .guest {
    border-left: 3px solid #f18a80;
  }
  .registered-customer {
    border-left: 3px solid #8582dc;
  }
}

.till-list {
  input[type="text"] {
    border: 1px solid #ebebf2;
    border-radius: 5px;
    font-family: "Source Sans Pro", sans-serif;
    width: calc(100% - 438px);
    border: 1px solid #ebebf2;
    color: #4d4f5c;
    padding-left: 15px;
    position: absolute;
    right: 314px;
    &:focus {
      outline: none;
    }
  }
  .tiledPanels .panelWrapper {
    width: 100%;
    .panel {
      width: 100%;
      &.notActive {
        background: #f1f1f5;
      }
      .editInfo {
        text-transform: uppercase;
        color: #ffffff;
        font-family: "Source Sans Pro", sans-serif;
        margin-top: 58px;
        span.edit {
          background: #0a162e;
          display: inline-block;
          width: 50%;
          text-align: center;
          cursor: pointer;
          line-height: 60px;
          i {
            padding-right: 5px;
          }
        }
        span.deleteTill {
          background: #00bdbc;
          cursor: pointer;
          display: inline-block;
          width: 50%;
          text-align: center;
          line-height: 60px;
          &.active,
          &:hover {
            background: #05a0a0;
            transition: all 1s;
            color: white;
            text-decoration: none;
          }
          i {
            padding-right: 5px;
          }
        }
      }
      .panel-body {
        margin: 19px;
        .tillInfo {
          width: 55%;
          margin-left: 45%;
          .types {
            color: #a3a4aa;
            font-family: "Source Sans Pro", sans-serif;
            min-height: 50px;
            em {
              position: absolute;
              margin-left: -149px;
              text-transform: uppercase;
              color: #4d4f5c;
              font-style: normal;
              font-family: "Source Sans Pro", sans-serif;
            }
          }
        }
      }
      .topBlock {
        margin: 19px;
        border-bottom: 1px solid #d6d6d6;
        .tillImage {
          color: #00bdbc;
          font-size: 43px;
          display: inline-block;
          vertical-align: top;
          padding-right: 15px;
        }
        .titleDiv {
          display: inline-block;
          width: calc(100% - 64px);
          color: #4d4f5c;
          font-size: 18px;
          p.tillStatus {
            color: #a3a4aa;
            text-transform: uppercase;
            font-size: 13px;
            margin-top: -3px;
          }
        }
      }
    }
  }
}

.tills-filter {
  font-size: 0.8em;
  .always-visible {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .btn1 {
      top: 0;
    }
  }

  .btn1 {
    margin-left: 0;
  }

  .reset-button {
    background-color: #00bdbc;
    border: #00bdbc;
    color: #fff;
    padding: 0.2em 0.5em;
    border-radius: 0.5em;
    margin-top: 0.25em;
    margin-right: 0.25em;
  }

  .radio-group {
    font-size: 0.9em;
    input[type="radio"] {
      display: none;
    }
    input[type="radio"] + label {
      padding: 0.25em 1em;
      border: 1px solid #00bdbc;
      margin: 0;
      color: #999;
    }
    input[type="radio"]:checked + label {
      background-color: #00bdbc;
      color: #fff;
    }
    input[type="radio"]:not(:checked) + label:hover {
      color: #000;
      cursor: pointer;
    }
    .form-check-inline,
    .form-check-inline .form-check-input {
      margin: 0;
      padding: 0;
    }
    .form-check:nth-of-type(1) label {
      border-top-left-radius: 0.6em;
      border-bottom-left-radius: 0.6em;
    }
    .form-check:nth-of-type(2) label {
      border-top-right-radius: 0.6em;
      border-bottom-right-radius: 0.6em;
    }
  }
  .togglable-filters {
    position: relative;
    font-size: 0.9em;
    background-color: #f1f1f1;
    max-height: 0;
    transition: max-height 1s;
    margin-bottom: 2em;
    overflow-y: hidden;
    &.visible {
      max-height: 600px;
    }
    .form-group {
      display: grid;
      grid-auto-columns: max-content;
      align-items: baseline;
      grid-column-gap: 1em;
      grid-row-gap: 1em;
      padding: 1em;
      & > *:nth-child(odd) {
        grid-column: 1;
      }
      & > *:nth-child(even) {
        grid-column: 2;
      }
    }
    label {
      font-weight: bold;
    }

    .form-check-input {
      position: unset;
    }
    input[type="text"] {
      position: unset;
      width: 30em;
      max-width: calc(100% - 12em);
    }
  }
}

@media screen and (max-width: 1199px) {
  .tills-filter {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 649px) {
  .tills-filter .togglable-filters .form-group {
    grid-auto-columns: 100%;

    & > *:nth-child(even) {
      grid-column: 1;
    }
  }
}

@media screen and (max-width: 400px) {
  .tills-filter .radio-group {
    display: grid;
    grid-auto-columns: 100%;

    > div {
      width: 100%;

      label {
        width: 100%;
        border-top-right-radius: unset !important;
        border-top-left-radius: unset !important;
        border-bottom-right-radius: unset !important;
        border-bottom-left-radius: unset !important;
      }
    }
  }
}
